// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parapara-container {
  background-color: #f6f6f6;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}

.currency-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 20px;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;  /* Genişliği %100 yap */
  height: 150px;
  transition: transform 0.3s ease;
}

.currency-card:hover {
  transform: translateY(-10px);
}

.currency-card.highlight {
  box-shadow: 0 0 20px rgba(255, 223, 0, 0.8);
}

.currency-icon {
  margin-bottom: 15px;
  font-size: 2.5rem;
}

.currency-amount {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  word-wrap: break-word;
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/Parapara/Parapara.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,qDAAqD;EACrD,yCAAyC;EACzC,WAAW,GAAG,uBAAuB;EACrC,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;EACX,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".parapara-container {\r\n  background-color: #f6f6f6;\r\n  padding: 20px;\r\n  border-radius: 20px;\r\n  text-align: center;\r\n}\r\n\r\n.currency-card {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 30px;\r\n  border-radius: 20px;\r\n  background: linear-gradient(135deg, #ffffff, #f0f0f0);\r\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);\r\n  width: 100%;  /* Genişliği %100 yap */\r\n  height: 150px;\r\n  transition: transform 0.3s ease;\r\n}\r\n\r\n.currency-card:hover {\r\n  transform: translateY(-10px);\r\n}\r\n\r\n.currency-card.highlight {\r\n  box-shadow: 0 0 20px rgba(255, 223, 0, 0.8);\r\n}\r\n\r\n.currency-icon {\r\n  margin-bottom: 15px;\r\n  font-size: 2.5rem;\r\n}\r\n\r\n.currency-amount {\r\n  font-size: 1.2rem;\r\n  font-weight: bold;\r\n  color: #333;\r\n  word-wrap: break-word;\r\n  white-space: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
