import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { Line, Bar } from "react-chartjs-2";
import axios from "axios";
import { message as antdMessage, Button, Select, Row, Col } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import Parapara from "../../components/Parapara/Parapara";
import GraphComponent from "../../components/GraphComponents/GraphComponents";
import CircularProgressBar from "../../components/CircularProgressBar/CircularProgressBar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler
);

const { Option } = Select;

const GelirDetayPage = () => {
  const [allGelir, setAllGelir] = useState([]);
  const [filteredGelir, setFilteredGelir] = useState([]);
  const [totalGelir, setTotalGelir] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedGelirCountries, setSelectedGelirCountries] = useState([]);
  const [selectedGelirYears, setSelectedGelirYears] = useState([]);
  const [selectedGelirMonths, setSelectedGelirMonths] = useState([]);
  const [selectedGelirFaaliyet, setSelectedGelirFaaliyet] = useState([]);
  const [selectedGelirAciklama, setSelectedGelirAciklama] = useState([]);
  const [availableGelirCountries, setAvailableGelirCountries] = useState([]);
  const [availableGelirYears, setAvailableGelirYears] = useState([]);
  const [availableGelirMonths, setAvailableGelirMonths] = useState([]);
  const [availableGelirFaaliyet, setAvailableGelirFaaliyet] = useState([]);
  const [availableGelirAciklama, setAvailableGelirAciklama] = useState([]);
  const [exchangeRates, setExchangeRates] = useState({});
  const [monthlyExpenditures, setMonthlyExpenditures] = useState([]);
  const [monthlyExpendituresData, setMonthlyExpendituresData] = useState({});
  const [progress, setProgress] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res1 = await axios.post("/gelirs/get-gelir");
      console.log("API Response:", res1.data);
      const gelirData = res1.data.map((item) => ({
        ...item,
        ay: monthNumberToName(item.ay),
      }));
      setAllGelir(gelirData);
      setFilteredGelir(gelirData); // Tüm verileri varsayılan olarak set et

      // Aylık harcamaları hesapla ve konsola yazdır
      const monthlyData = calculateMonthlyExpendituresForSpecificYears(
        gelirData,
        [2023, 2024]
      );
      console.log("Monthly Expenditures:", monthlyData);

      setMonthlyExpendituresData(monthlyData); // Aylık harcamaları state olarak set et
      setProgress(100);
      updateGelirFilters(gelirData); // Veriler çekildikten sonra filtreleri güncelle
      calculateTotals(gelirData); // Veriler çekildikten sonra toplam gelirleri hesapla
      calculateTotalIncomeByExchange(gelirData); // Veriler çekildikten sonra döviz kurlarına göre toplam gelirleri hesapla
      const progressOrani = calculateProgress(gelirData, gelirData);
      setProgress(progressOrani);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      antdMessage.error("Veri alınırken bir hata oluştu");
      setLoading(false);
    }
  };

  const calculateProgress = (filteredData, allData) => {
    const genelToplam = allData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );

    const filteredToplam = filteredData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );

    return genelToplam === 0
      ? 0
      : ((filteredToplam / genelToplam) * 100).toFixed(2);
  };

  const calculateMonthlyExpendituresForSpecificYears = (data, years) => {
    const monthlyExpendituresDatas = {};

    years.forEach((year) => {
      monthlyExpendituresDatas[year] = Array(12).fill(0); // Her ay için 0 değerini başlangıç olarak ayarlayın
    });

    data.forEach((item) => {
      const date = new Date(item.tarih);
      const year = date.getFullYear();
      const month = date.getMonth(); // getMonth() 0-11 arasında değer döner
      if (years.includes(year)) {
        const amount = parseFloat(item.tutar.replace(",", "."));
        monthlyExpendituresDatas[year][month] += amount;
      }
    });

    return monthlyExpendituresDatas;
  };

  useEffect(() => {
    fetchData(); // Sayfa yüklendiğinde veri çek
  }, []); // Boş bağımlılıklar dizisi, bu effect'in sadece bileşen ilk yüklendiğinde çalışmasını sağlar

  useEffect(() => {
    updateGelirFilters(allGelir);
  }, [
    selectedGelirCountries,
    selectedGelirYears,
    selectedGelirMonths,
    selectedGelirFaaliyet,
    selectedGelirAciklama,
  ]);

  const updateGelirFilters = (gelirData) => {
    if (!gelirData) {
      gelirData = allGelir; // Eğer gelirData undefined ise allGelir kullan
    }

    const filteredData = gelirData.filter(
      (item) =>
        (selectedGelirYears.length === 0 ||
          selectedGelirYears.includes(item.yil)) &&
        (selectedGelirMonths.length === 0 ||
          selectedGelirMonths.includes(item.ay)) &&
        (selectedGelirCountries.length === 0 ||
          selectedGelirCountries.includes(item.bolge)) &&
        (selectedGelirFaaliyet.length === 0 ||
          selectedGelirFaaliyet.includes(item.faaliyet)) &&
        (selectedGelirAciklama.length === 0 ||
          selectedGelirAciklama.includes(item.aciklama))
    );

    setFilteredGelir(filteredData);
    calculateTotals(filteredData);
    calculateTotalIncomeByExchange(filteredData);
    calculateMonthlyExpenditures(filteredData);
    // Progress oranını hesapla ve state olarak set et
    const progressOrani = calculateProgress(filteredData, allGelir);
    setProgress(progressOrani);

    setAvailableGelirYears([
      ...new Set(
        gelirData
          .filter(
            (item) =>
              (selectedGelirMonths.length === 0 ||
                selectedGelirMonths.includes(item.ay)) &&
              (selectedGelirCountries.length === 0 ||
                selectedGelirCountries.includes(item.bolge)) &&
              (selectedGelirFaaliyet.length === 0 ||
                selectedGelirFaaliyet.includes(item.faaliyet)) &&
              (selectedGelirAciklama.length === 0 ||
                selectedGelirAciklama.includes(item.aciklama))
          )
          .map((item) => item.yil)
      ),
    ]);
    setAvailableGelirMonths(
      [
        ...new Set(
          gelirData
            .filter(
              (item) =>
                (selectedGelirYears.length === 0 ||
                  selectedGelirYears.includes(item.yil)) &&
                (selectedGelirCountries.length === 0 ||
                  selectedGelirCountries.includes(item.bolge)) &&
                (selectedGelirFaaliyet.length === 0 ||
                  selectedGelirFaaliyet.includes(item.faaliyet)) &&
                (selectedGelirAciklama.length === 0 ||
                  selectedGelirAciklama.includes(item.aciklama))
            )
            .map((item) => item.ay)
        ),
      ].sort((a, b) => {
        const monthsOrder = [
          "Oca",
          "Şub",
          "Mar",
          "Nis",
          "May",
          "Haz",
          "Tem",
          "Ağu",
          "Eyl",
          "Eki",
          "Kas",
          "Ara",
        ];
        return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
      })
    );
    setAvailableGelirCountries([
      ...new Set(
        gelirData
          .filter(
            (item) =>
              (selectedGelirYears.length === 0 ||
                selectedGelirYears.includes(item.yil)) &&
              (selectedGelirMonths.length === 0 ||
                selectedGelirMonths.includes(item.ay)) &&
              (selectedGelirFaaliyet.length === 0 ||
                selectedGelirFaaliyet.includes(item.faaliyet)) &&
              (selectedGelirAciklama.length === 0 ||
                selectedGelirAciklama.includes(item.aciklama))
          )
          .map((item) => item.bolge)
      ),
    ]);
    setAvailableGelirFaaliyet([
      ...new Set(
        gelirData
          .filter(
            (item) =>
              (selectedGelirYears.length === 0 ||
                selectedGelirYears.includes(item.yil)) &&
              (selectedGelirMonths.length === 0 ||
                selectedGelirMonths.includes(item.ay)) &&
              (selectedGelirCountries.length === 0 ||
                selectedGelirCountries.includes(item.bolge)) &&
              (selectedGelirAciklama.length === 0 ||
                selectedGelirAciklama.includes(item.aciklama))
          )
          .map((item) => item.faaliyet)
      ),
    ]);

    setAvailableGelirAciklama([
      ...new Set(
        gelirData
          .filter(
            (item) =>
              (selectedGelirYears.length === 0 ||
                selectedGelirYears.includes(item.yil)) &&
              (selectedGelirMonths.length === 0 ||
                selectedGelirMonths.includes(item.ay)) &&
              (selectedGelirCountries.length === 0 ||
                selectedGelirCountries.includes(item.bolge)) &&
              (selectedGelirFaaliyet.length === 0 ||
                selectedGelirFaaliyet.includes(item.faaliyet))
          )
          .map((item) => item.aciklama)
      ),
    ]);
  };

  const calculateTotalIncomeByExchange = (data) => {
    const exchangeRates = {};

    data.forEach((item) => {
      const doviz = item.doviz;
      const income = parseFloat(item.deger.replace(",", "."));
      if (!exchangeRates[doviz]) {
        exchangeRates[doviz] = 0;
      }
      exchangeRates[doviz] += income;
    });

    console.log("Vericim:", data);
    console.log("Exchange Rates:", exchangeRates);

    setExchangeRates(exchangeRates);
  };

  const calculateMonthlyExpenditures = (data) => {
    const monthlyExpendituresData = {};
    data.forEach((item) => {
      const month = item.ay;
      const amount = parseFloat(item.tutar.replace(",", "."));
      if (!monthlyExpendituresData[month]) {
        monthlyExpendituresData[month] = 0;
      }
      monthlyExpendituresData[month] += amount;
    });

    setMonthlyExpenditures(monthlyExpendituresData);
  };

  const monthNumberToName = (monthNumber) => {
    const months = [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ];
    return months[monthNumber - 1]; // Ay numaraları 1-12 arasında olduğu varsayıldı.
  };

  const calculateTotals = (gelirData) => {
    const totalGelir = gelirData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );
    console.log("Veri:", gelirData);
    console.log("Veriler:", totalGelir);
    setTotalGelir(totalGelir);
  };

  const clearSelection = (category, type) => {
    if (type === "gelir") {
      switch (category) {
        case "Countries":
          setSelectedGelirCountries([]);
          break;
        case "Years":
          setSelectedGelirYears([]);
          break;
        case "Months":
          setSelectedGelirMonths([]);
          break;
        case "Faaliyet":
          setSelectedGelirFaaliyet([]);
          break;
        case "Aciklama":
          setSelectedGelirAciklama([]);
          break;
        default:
          break;
      }
    }
  };

  const selectAll = (category, type) => {
    if (type === "gelir") {
      switch (category) {
        case "Countries":
          setSelectedGelirCountries([
            ...new Set(allGelir.map((item) => item.bolge)),
          ]);
          break;
        case "Years":
          setSelectedGelirYears([...new Set(allGelir.map((item) => item.yil))]);
          break;

        case "Faaliyet":
          setSelectedGelirFaaliyet([
            ...new Set(allGelir.map((item) => item.faaliyet)),
          ]);
          break;
        case "Aciklama":
          setSelectedGelirAciklama([
            ...new Set(allGelir.map((item) => item.aciklama)),
          ]);
          break;
        case "Months":
          setSelectedGelirMonths(
            [
              ...new Set(allGelir.map((item) => monthNumberToName(item.ay))),
            ].sort((a, b) => {
              const monthsOrder = [
                "Oca",
                "Şub",
                "Mar",
                "Nis",
                "May",
                "Haz",
                "Tem",
                "Ağu",
                "Eyl",
                "Eki",
                "Kas",
                "Ara",
              ];
              return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
            })
          );
          break;
        default:
          break;
      }
    }
  };

  const datas = {
    labels: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    datasets: [
      {
        label: "2023 Gelir",
        data: monthlyExpendituresData[2023] || Array(12).fill(0),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "2024 Gelir",
        data: monthlyExpendituresData[2024] || Array(12).fill(0),
        backgroundColor: "rgba(192, 75, 192, 0.6)",
        borderColor: "rgba(192, 75, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1000000, // Adım boyutunu belirleyin
          callback: function (value) {
            console.log("Y ekseni değeri:", value);
            return value.toLocaleString("tr-TR", {
              style: "currency",
              currency: "TRY",
            }); // Y eksenindeki değerlerin tutar olarak gösterimi
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.raw.toLocaleString("tr-TR", {
              style: "currency",
              currency: "TRY",
            }); // Tooltip'te tutar olarak gösterim
          },
        },
      },
    },
  };

  return (
    <Layout>
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Parapara calculateTotalIncomeByExchange={exchangeRates} />
        )}
      </div>
      <div>
        <CircularProgressBar progress={progress} />
      </div>

      <div
        style={{
          padding: "20px",
          backgroundColor: "#f0f0f0",
          minHeight: "100vh",
        }}
      >
        <h1>Gelir Detayı</h1>
        <p>
          Bu kısımda kurumun mevcut gelir durumu ve gelirlerin çeşitli alt
          kalemlere göre dağılımlarının detaylarını görüntülemekteyiz. Yönetici
          detaylı inceleyerek gerekli stratejik planlamayı yapabilir.
        </p>

        <div style={{ marginBottom: "20px" }}>
          <h2>Filtreler</h2>
          <Row gutter={[16, 16]}>
            <Col>
              <h3>Ülkeler</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Ülke Seçin"
                value={selectedGelirCountries}
                onChange={(value) => setSelectedGelirCountries(value)}
              >
                {availableGelirCountries.map((country) => (
                  <Option key={country} value={country}>
                    {country}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Countries", "gelir")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Countries", "gelir")}>
                Hepsini Seç
              </Button>
            </Col>
            <Col>
              <h3>Faaliyet</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Faaliyet Seçin"
                value={selectedGelirFaaliyet}
                onChange={(value) => setSelectedGelirFaaliyet(value)}
              >
                {availableGelirFaaliyet.map((faaliyet) => (
                  <Option key={faaliyet} value={faaliyet}>
                    {faaliyet}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Faaliyet", "gelir")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Faaliyet", "gelir")}>
                Hepsini Seç
              </Button>
            </Col>
            <Col>
              <h3>Açıklama</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Açıklama Seçin"
                value={selectedGelirAciklama}
                onChange={(value) => setSelectedGelirAciklama(value)}
              >
                {availableGelirAciklama.map((aciklama) => (
                  <Option key={aciklama} value={aciklama}>
                    {aciklama}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Aciklama", "gelir")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Aciklama", "gelir")}>
                Hepsini Seç
              </Button>
            </Col>
            <Col>
              <h3>Yıllar</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Yıl Seçin"
                value={selectedGelirYears}
                onChange={(value) => setSelectedGelirYears(value)}
              >
                {availableGelirYears.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Years", "gelir")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Years", "gelir")}>
                Hepsini Seç
              </Button>
            </Col>
            <Col>
              <h3>Aylar</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Ay Seçin"
                value={selectedGelirMonths}
                onChange={(value) => setSelectedGelirMonths(value)}
              >
                {availableGelirMonths.map((month) => (
                  <Option key={month} value={month}>
                    {month}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Months", "gelir")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Months", "gelir")}>
                Hepsini Seç
              </Button>
            </Col>
          </Row>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div>
            <h2>Toplam</h2>
            <h3>{totalGelir.toLocaleString()} ₺</h3>
          </div>
        </div>
        <GraphComponent data={monthlyExpenditures} />
        <h3>2023-2024 Gelir Karşılaştırması</h3>
        <Bar data={datas} options={options} />
      </div>
    </Layout>
  );
};

export default GelirDetayPage;
