import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import './ResetPassword.css';

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Şifreler eşleşmiyor');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.put(`/auth/resetPassword/${token}`, { password });
            setMessage(response.data.message);
            navigate('/login'); // Başarılı şifre sıfırlama işleminden sonra yönlendirme
        } catch (error) {
            setMessage(error.response?.data?.message || 'Şifre sıfırlanırken hata oluştu');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-page">
            <div className="wrapper">
                <div className="form-box reset-password">
                    <h1>Reset Password</h1>
                    {message && <div className="alert">{message}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="input-box">
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Şifre"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <FaLock className="icon" />
                            <div className="eye-icon" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                        <div className="input-box">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Şifre Doğrulama"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <FaLock className="icon" />
                            <div className="eye-icon" onClick={toggleShowConfirmPassword}>
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                        <button type="submit" disabled={loading}>
                            Reset Password
                        </button>
                    </form>
                    {loading && <div className="loading">Loading...</div>}
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
