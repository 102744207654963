import React from "react";
import "./CircularProgressBar.css"; // CSS dosyasını oluşturduktan sonra ekleyin

const CircularProgressBar = ({ progress }) => {
  const strokeDashoffset = 314 - (314 * progress) / 100; // Çemberin çevresi: 2πr = 2π50 ≈ 314 (r=50)

  return (
    <div className="circular-progress-bar">
      <svg width="150" height="150" viewBox="0 0 100 100">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: "#ff00ff", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#00ffff", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <circle
          className="circular-progress-bar__background"
          cx="50"
          cy="50"
          r="45"
        />
        <circle
          className="circular-progress-bar__foreground"
          cx="50"
          cy="50"
          r="45"
          strokeDasharray="314"
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <div className="circular-progress-bar__text">{`${progress}%`}</div>
    </div>
  );
};

export default CircularProgressBar;
