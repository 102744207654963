import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = () => {
    const { user, loading } = useContext(AuthContext);
    const token = localStorage.getItem('token');

    if (loading) {
        return <div>Loading...</div>; // Yüklenme durumu
    }

    if (!user ) {
        return <Navigate to="/login" />;
    }

   // if (user.isSuspended) {        return <Navigate to="/suspended" />;}

    return <Outlet />;
};

export default ProtectedRoute;

