import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Alert,
  CircularProgress,
  Container,
  Typography,
  Grid,
  Paper,
  styled,
  Box,
} from "@mui/material";
import Layout from "../Layout/Layout";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
}));

// Doğrulama şemasını oluşturun
const validationSchema = Yup.object().shape({
  tarih: Yup.string().required("Please input the date!"),
  rapor: Yup.string().required("Please input the report date!"),
  faaliyet: Yup.string().required("Please input the activity!"),
  kaynak: Yup.string().required("Please input the source!"),
  bolge: Yup.string().required("Please input the region!"),
  deger: Yup.string().required("Please input the value!"),
  doviz: Yup.string().required("Please input the currency!"),
  tutar: Yup.string().required("Please input the amount!"),
  tur: Yup.string().required("Please input the type!"),

  partner: Yup.string(),
  aciklama: Yup.string().required("Please input the description!"),
  yil: Yup.string().required("Please input the year!"),
  ay: Yup.string().required("Please input the month!"),
});

const AddIncome = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await axios.post("/gelirs/add-gelir", values);
      setMessage("Income added successfully");
      resetForm();
    } catch (error) {
      setMessage("Error adding income");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      {" "}
      <StyledContainer maxWidth="md">
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Add Income
        </Typography>
        <StyledPaper elevation={3}>
          <Formik
            initialValues={{
              tarih: "",
              rapor: "",
              faaliyet: "",
              kaynak: "",
              bolge: "",
              deger: "",
              doviz: "",
              tutar: "",
              tur: "",
              partner: "",
              aciklama: "",
              yil: "",
              ay: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="tarih"
                      label="Tarih"
                      variant="outlined"
                      fullWidth
                      error={touched.tarih && !!errors.tarih}
                      helperText={touched.tarih && errors.tarih}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="rapor"
                      label="Rapor"
                      variant="outlined"
                      fullWidth
                      error={touched.rapor && !!errors.rapor}
                      helperText={touched.rapor && errors.rapor}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="faaliyet"
                      label="Faaliyet"
                      variant="outlined"
                      fullWidth
                      error={touched.faaliyet && !!errors.faaliyet}
                      helperText={touched.faaliyet && errors.faaliyet}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="kaynak"
                      label="Kaynak"
                      variant="outlined"
                      fullWidth
                      error={touched.kaynak && !!errors.kaynak}
                      helperText={touched.kaynak && errors.kaynak}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="bolge"
                      label="Bölge"
                      variant="outlined"
                      fullWidth
                      error={touched.bolge && !!errors.bolge}
                      helperText={touched.bolge && errors.bolge}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="deger"
                      label="Değer"
                      variant="outlined"
                      fullWidth
                      error={touched.deger && !!errors.deger}
                      helperText={touched.deger && errors.deger}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="doviz"
                      label="Döviz"
                      variant="outlined"
                      fullWidth
                      error={touched.doviz && !!errors.doviz}
                      helperText={touched.doviz && errors.doviz}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="tutar"
                      label="Tutar"
                      variant="outlined"
                      fullWidth
                      error={touched.tutar && !!errors.tutar}
                      helperText={touched.tutar && errors.tutar}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="tur"
                      label="Tür"
                      variant="outlined"
                      fullWidth
                      error={touched.tur && !!errors.tur}
                      helperText={touched.tur && errors.tur}
                    />
                  </Grid>
                                    <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="partner"
                      label="Partner"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="aciklama"
                      label="Açıklama"
                      variant="outlined"
                      fullWidth
                      error={touched.aciklama && !!errors.aciklama}
                      helperText={touched.aciklama && errors.aciklama}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="yil"
                      label="Yıl"
                      variant="outlined"
                      fullWidth
                      error={touched.yil && !!errors.yil}
                      helperText={touched.yil && errors.yil}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="ay"
                      label="Ay"
                      variant="outlined"
                      fullWidth
                      error={touched.ay && !!errors.ay}
                      helperText={touched.ay && errors.ay}
                    />
                  </Grid>
                </Grid>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading ? <CircularProgress size={24} /> : "Add Income"}
                </StyledButton>
              </Form>
            )}
          </Formik>
        </StyledPaper>
        {message && (
          <Alert severity="success" style={{ marginTop: "20px" }}>
            {message}
          </Alert>
        )}
      </StyledContainer>
    </Layout>
  );
};

export default AddIncome;
