import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ActivateAccount = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await axios.get(`/auth/activate/${token}`);
                setMessage(response.data.message);
                navigate('/login'); // Başarılı aktivasyon sonrası giriş sayfasına yönlendirme
            } catch (error) {
                setMessage(error.response?.data?.message || 'Hesap aktive edilirken sorun oluştu');
            }
        };
        activateAccount();
    }, [token, navigate]);

    return (
        <div>
            <h2>Account Activation</h2>
            <p>{message}</p>
        </div>
    );
};

export default ActivateAccount;
