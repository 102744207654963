import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import axios from "axios";
import {
  message as antdMessage,
  Button,
  Select,
  Row,
  Col,
  Table,
  Progress,
} from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Parapara from "../../components/Parapara/Parapara";
import GraphComponent from "../../components/GraphComponents/GraphComponents";
import CircularProgressBar from "../../components/CircularProgressBar/CircularProgressBar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler
);

const { Option } = Select;
const columns = [
  {
    title: "Alt Gider Kalemi",
    dataIndex: "alt_gider_kalemi",
    key: "alt_gider_kalemi",
  },
  {
    title: "Tutar",
    dataIndex: "tutar",
    key: "tutar",
    render: (text) => `${text.toLocaleString()} ₺`,
  },
  {
    title: "Oran (%)",
    dataIndex: "oran",
    key: "oran",
    render: (text) => `${text.toFixed(2)}%`,
  },
  {
    title: "Progress",
    dataIndex: "progress",
    key: "progress",
    render: (text) => <Progress percent={text.toFixed(2)} status="active" />,
  },
];

const GiderDetayPage = () => {
  const [allGider, setAllGider] = useState([]);
  const [filteredGider, setFilteredGider] = useState([]);
  const [totalGider, setTotalGider] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedGiderSources, setSelectedGiderSources] = useState([]);
  const [selectedGiderYears, setSelectedGiderYears] = useState([]);
  const [selectedGiderMonths, setSelectedGiderMonths] = useState([]);
  const [selectedGiderFaaliyet, setSelectedGiderFaaliyet] = useState([]);
  const [selectedGiderAciklama, setSelectedGiderAciklama] = useState([]);
  const [availableGiderSources, setAvailableGiderSources] = useState([]);
  const [availableGiderYears, setAvailableGiderYears] = useState([]);
  const [availableGiderMonths, setAvailableGiderMonths] = useState([]);
  const [availableGiderFaaliyet, setAvailableGiderFaaliyet] = useState([]);
  const [availableGiderAciklama, setAvailableGiderAciklama] = useState([]);
  const [exchangeRates, setExchangeRates] = useState({});
  const [monthlyExpenditures, setMonthlyExpenditures] = useState([]);
  const [monthlyExpendituresData, setMonthlyExpendituresData] = useState({});
  const [progress, setProgress] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [yearlyData, setYearlyData] = useState({});

  useEffect(() => {
    fetchData(); // Sayfa yüklendiğinde veri çek
  }, []); // Boş bağımlılıklar dizisi, bu effect'in sadece bileşen ilk yüklendiğinde çalışmasını sağlar

  const fetchData = async () => {
    try {
      setLoading(true);
      const res1 = await axios.post("/giders/get-gider");
      console.log("API Response:", res1.data);
      setAllGider(res1.data);
      setFilteredGider(res1.data); // Tüm verileri varsayılan olarak set et

      // Aylık harcamaları hesapla ve konsola yazdır
      const monthlyData = calculateMonthlyExpendituresForSpecificYears(
        res1.data,
        [2023, 2024]
      );
      console.log("Monthly Expenditures:", monthlyData);

      setMonthlyExpendituresData(monthlyData); // Aylık harcamaları state olarak set et
      setProgress(100);

      // Filtreleri ve diğer verileri güncelle
      updateGiderFilters(res1.data); // Veri çekildikten sonra filtreleri güncelle
      setLoading(false);

      // Yıllık verileri hesapla
      const yearlyData = calculateYearlyData(res1.data);
      setYearlyData(yearlyData);
    } catch (error) {
      console.log("Error fetching data:", error);
      antdMessage.error("Veri alınırken bir hata oluştu");
      setLoading(false);
    }
  };

  const calculateProgress = (filteredData, allData) => {
    const genelToplam = allData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );

    const filteredToplam = filteredData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );

    return genelToplam === 0
      ? 0
      : ((filteredToplam / genelToplam) * 100).toFixed(2);
  };

  const calculateMonthlyExpendituresForSpecificYears = (data, years) => {
    const monthlyExpendituresDatas = {};

    years.forEach((year) => {
      monthlyExpendituresDatas[year] = Array(12).fill(0); // Her ay için 0 değerini başlangıç olarak ayarlayın
    });

    data.forEach((item) => {
      const date = new Date(item.tarih);
      const year = date.getFullYear();
      const month = date.getMonth(); // getMonth() 0-11 arasında değer döner
      if (years.includes(year)) {
        const amount = parseFloat(item.tutar.replace(",", "."));
        monthlyExpendituresDatas[year][month] += amount;
      }
    });

    return monthlyExpendituresDatas;
  };

  const calculateYearlyData = (data) => {
    const yearlyData = {};

    data.forEach((item) => {
      const year = new Date(item.tarih).getFullYear();
      const amount = parseFloat(item.tutar.replace(",", "."));
      if (!yearlyData[year]) {
        yearlyData[year] = 0;
      }
      yearlyData[year] += amount;
    });

    return yearlyData;
  };

  useEffect(() => {
    fetchData(); // Sayfa yüklendiğinde veri çek
  }, []); // Boş bağımlılıklar dizisi, bu effect'in sadece bileşen ilk yüklendiğinde çalışmasını sağlar

  useEffect(() => {
    updateGiderFilters(allGider); // Filtreler değiştiğinde güncelle
  }, [
    selectedGiderSources,
    selectedGiderYears,
    selectedGiderMonths,
    selectedGiderFaaliyet,
    selectedGiderAciklama,
  ]);

  const updateGiderFilters = (allGiderData) => {
    if (!allGiderData) return;

    const filteredData = allGiderData.filter(
      (item) =>
        (selectedGiderYears.length === 0 ||
          selectedGiderYears.includes(item.yil)) &&
        (selectedGiderMonths.length === 0 ||
          selectedGiderMonths.includes(monthNumberToName(item.ay))) &&
        (selectedGiderSources.length === 0 ||
          selectedGiderSources.includes(item.alt_gider_kalemi)) &&
        (selectedGiderFaaliyet.length === 0 ||
          selectedGiderFaaliyet.includes(item.alt_gider_turu)) &&
        (selectedGiderAciklama.length === 0 ||
          selectedGiderAciklama.includes(item.aciklama))
    );

    setFilteredGider(filteredData);
    calculateTotals(filteredData);
    calculateTotalIncomeByExchange(filteredData);
    calculateMonthlyExpenditures(filteredData);

    // Progress oranını hesapla ve state olarak set et
    const progressOrani = calculateProgress(filteredData, allGiderData);
    setProgress(progressOrani);

    // alt_gider_turlerine göre gruplayarak toplam tutarları hesapla
    const groupedData = filteredData.reduce((acc, item) => {
      const key = item.alt_gider_kalemi;
      if (!acc[key]) {
        acc[key] = { tutar: 0 };
      }
      acc[key].tutar += parseFloat(item.tutar.replace(",", "."));
      return acc;
    }, {});

    // Debug için konsola yazdır
    console.log("Grouped Data:", groupedData);

    // Tabloda kullanılacak verileri hazırla
    const totalGider = Object.values(groupedData).reduce(
      (sum, item) => sum + item.tutar,
      0
    );

    console.log("Total Gider:", totalGider); // Debug için toplam gideri yazdır

    const tableData = Object.entries(groupedData).map(([key, { tutar }]) => {
      const oran = (tutar / totalGider) * 100;
      return {
        key: key,
        alt_gider_kalemi: key,
        tutar: tutar,
        oran: oran,
        progress: oran,
      };
    });

    // Debug için konsola yazdır
    console.log("Table Data:", tableData);

    setTableData(tableData);

    // Eşsiz filtre seçeneklerini belirle
    setAvailableGiderYears(
      [
        ...new Set(
          allGiderData
            .filter(
              (item) =>
                (selectedGiderMonths.length === 0 ||
                  selectedGiderMonths.includes(monthNumberToName(item.ay))) &&
                (selectedGiderSources.length === 0 ||
                  selectedGiderSources.includes(item.alt_gider_kalemi)) &&
                (selectedGiderFaaliyet.length === 0 ||
                  selectedGiderFaaliyet.includes(item.alt_gider_turu)) &&
                (selectedGiderAciklama.length === 0 ||
                  selectedGiderAciklama.includes(item.aciklama))
            )
            .map((item) => item.yil)
        ),
      ].sort((a, b) => a - b)
    );

    setAvailableGiderMonths(
      [
        ...new Set(
          allGiderData
            .filter(
              (item) =>
                (selectedGiderYears.length === 0 ||
                  selectedGiderYears.includes(item.yil)) &&
                (selectedGiderSources.length === 0 ||
                  selectedGiderSources.includes(item.alt_gider_kalemi)) &&
                (selectedGiderFaaliyet.length === 0 ||
                  selectedGiderFaaliyet.includes(item.alt_gider_turu)) &&
                (selectedGiderAciklama.length === 0 ||
                  selectedGiderAciklama.includes(item.aciklama))
            )
            .map((item) => monthNumberToName(item.ay))
        ),
      ].sort((a, b) => {
        const monthsOrder = [
          "Oca",
          "Şub",
          "Mar",
          "Nis",
          "May",
          "Haz",
          "Tem",
          "Ağu",
          "Eyl",
          "Eki",
          "Kas",
          "Ara",
        ];
        return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
      })
    );

    setAvailableGiderSources([
      ...new Set(
        allGiderData
          .filter(
            (item) =>
              (selectedGiderYears.length === 0 ||
                selectedGiderYears.includes(item.yil)) &&
              (selectedGiderMonths.length === 0 ||
                selectedGiderMonths.includes(monthNumberToName(item.ay))) &&
              (selectedGiderFaaliyet.length === 0 ||
                selectedGiderFaaliyet.includes(item.alt_gider_turu)) &&
              (selectedGiderAciklama.length === 0 ||
                selectedGiderAciklama.includes(item.aciklama))
          )
          .map((item) => item.alt_gider_kalemi)
      ),
    ]);

    setAvailableGiderFaaliyet([
      ...new Set(
        allGiderData
          .filter(
            (item) =>
              (selectedGiderYears.length === 0 ||
                selectedGiderYears.includes(item.yil)) &&
              (selectedGiderMonths.length === 0 ||
                selectedGiderMonths.includes(monthNumberToName(item.ay))) &&
              (selectedGiderSources.length === 0 ||
                selectedGiderSources.includes(item.alt_gider_kalemi)) &&
              (selectedGiderAciklama.length === 0 ||
                selectedGiderAciklama.includes(item.aciklama))
          )
          .map((item) => item.alt_gider_turu)
      ),
    ]);
    setAvailableGiderAciklama([
      ...new Set(
        allGiderData
          .filter(
            (item) =>
              (selectedGiderYears.length === 0 ||
                selectedGiderYears.includes(item.yil)) &&
              (selectedGiderMonths.length === 0 ||
                selectedGiderMonths.includes(monthNumberToName(item.ay))) &&
              (selectedGiderSources.length === 0 ||
                selectedGiderSources.includes(item.alt_gider_kalemi)) &&
              (selectedGiderFaaliyet.length === 0 ||
                selectedGiderFaaliyet.includes(item.alt_gider_turu))
          )
          .map((item) => item.aciklama)
      ),
    ]);
  };

  const monthNumberToName = (month) => {
    const months = [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ];
    return months[month - 1]; // Ay numarası 1-12 arasında olduğu varsayılarak
  };

  const calculateTotalIncomeByExchange = (data) => {
    const exchangeRates = {};

    data.forEach((item) => {
      const doviz = item.doviz;
      const income = parseFloat(item.deger.replace(",", "."));
      if (!exchangeRates[doviz]) {
        exchangeRates[doviz] = 0;
      }
      exchangeRates[doviz] += income;
    });

    console.log("Vericim:", data);
    console.log("Exchange Rates:", exchangeRates);

    setExchangeRates(exchangeRates);
  };

  const calculateMonthlyExpenditures = (data) => {
    const monthlyExpendituresData = {};
    data.forEach((item) => {
      const month = item.ay;
      const amount = parseFloat(item.tutar.replace(",", "."));
      if (!monthlyExpendituresData[month]) {
        monthlyExpendituresData[month] = 0;
      }
      monthlyExpendituresData[month] += amount;
    });

    setMonthlyExpenditures(monthlyExpendituresData);
  };

  const calculateTotals = (giderData) => {
    const totalGider = giderData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );
    console.log("Veri:", giderData);
    console.log("Veriler:", totalGider);
    setTotalGider(totalGider);
  };

  const clearSelection = (category, type) => {
    if (type === "gider") {
      switch (category) {
        case "Sources":
          setSelectedGiderSources([]);
          break;
        case "Years":
          setSelectedGiderYears([]);
          break;
        case "Months":
          setSelectedGiderMonths([]);
          break;
        case "Alt Gider Türü":
          setSelectedGiderFaaliyet([]);
          break;

        case "Aciklama":
          setSelectedGiderAciklama([]);
          break;
        default:
          break;
      }
      updateGiderFilters(allGider);
    }
  };

  const selectAll = (category, type) => {
    if (type === "gider") {
      switch (category) {
        case "Sources":
          setSelectedGiderSources([
            ...new Set(allGider.map((item) => item.alt_gider_kalemi)),
          ]);
          break;
        case "Years":
          setSelectedGiderYears([...new Set(allGider.map((item) => item.yil))]);
          break;
        case "Months":
          setSelectedGiderMonths(
            [...new Set(allGider.map((item) => item.ay))].sort((a, b) => {
              const monthsOrder = [
                "Ocak",
                "Şubat",
                "Mart",
                "Nisan",
                "Mayıs",
                "Haziran",
                "Temmuz",
                "Ağustos",
                "Eylül",
                "Ekim",
                "Kasım",
                "Aralık",
              ];
              return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
            })
          );
          break;
        case "Alt Gider Türü":
          setSelectedGiderFaaliyet([
            ...new Set(allGider.map((item) => item.alt_gider_turu)),
          ]);
          break;
        case "Aciklama":
          setSelectedGiderAciklama([
            ...new Set(allGider.map((item) => item.aciklama)),
          ]);
          break;
        default:
          break;
      }
      updateGiderFilters(allGider); // Tümünü seçerken filtreleri güncelle
    }
  };

  const generateBarChartData = (yearlyData) => {
    const labels = Object.keys(yearlyData);
    const data = Object.values(yearlyData);

    return {
      labels: labels,
      datasets: [
        {
          label: "Yıllık Toplam Gider",
          data: data,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <Layout>
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Parapara calculateTotalIncomeByExchange={exchangeRates} />
        )}
      </div>
      <div>
        <CircularProgressBar progress={progress} />
      </div>

      <div
        style={{
          padding: "20px",
          backgroundColor: "#f0f0f0",
          minHeight: "100vh",
        }}
      >
        <h1>Gider Detayı</h1>
        <p>
          Bu kısımda kurumun mevcut gider durumu ve giderlerin çeşitli alt
          kalemlere göre dağılımlarının detaylarını görüntülemekteyiz. Yönetici
          detaylı inceleyerek gerekli stratejik planlamayı yapabilir.
        </p>

        <div style={{ marginBottom: "20px" }}>
          <h2>Filtreler</h2>
          <Row gutter={[16, 16]}>
            <Col>
              <h3>Alt Gider Kalemi</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Alt Gider Kalemi Seçin"
                value={selectedGiderSources}
                onChange={(value) => setSelectedGiderSources(value)}
              >
                {availableGiderSources.map((source) => (
                  <Option key={source} value={source}>
                    {source}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Sources", "gider")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Sources", "gider")}>
                Hepsini Seç
              </Button>
            </Col>
            <Col>
              <h3>Alt Gider Türü</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Alt Gider Türü Seçin"
                value={selectedGiderFaaliyet}
                onChange={(value) => setSelectedGiderFaaliyet(value)}
              >
                {availableGiderFaaliyet.map((alt_gider_turu) => (
                  <Option key={alt_gider_turu} value={alt_gider_turu}>
                    {alt_gider_turu}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Alt Gider Türü", "gider")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Alt Gider Türü", "gider")}>
                Hepsini Seç
              </Button>
            </Col>
            <Col>
              <h3>Açıklama</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Açıklama Seçin"
                value={selectedGiderAciklama}
                onChange={(value) => setSelectedGiderAciklama(value)}
              >
                {availableGiderAciklama.map((aciklama) => (
                  <Option key={aciklama} value={aciklama}>
                    {aciklama}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Aciklama", "gider")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Aciklama", "gider")}>
                Hepsini Seç
              </Button>
            </Col>
            <Col>
              <h3>Aylar</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Ay Seçin"
                value={selectedGiderMonths}
                onChange={(value) => setSelectedGiderMonths(value)}
              >
                {availableGiderMonths.map((month) => (
                  <Option key={month} value={month}>
                    {month}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Months", "gider")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Months", "gider")}>
                Hepsini Seç
              </Button>
            </Col>

            <Col>
              <h3>Yıllar</h3>
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Yıl Seçin"
                value={selectedGiderYears}
                onChange={(value) => setSelectedGiderYears(value)}
              >
                {availableGiderYears.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
              <Button onClick={() => clearSelection("Years", "gider")}>
                Temizle
              </Button>
              <Button onClick={() => selectAll("Years", "gider")}>
                Hepsini Seç
              </Button>
            </Col>
          </Row>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div>
            <h2>Toplam</h2>
            <h3>{totalGider.toLocaleString()} ₺</h3>
          </div>
        </div>
        <GraphComponent data={monthlyExpenditures} />
        <Table columns={columns} dataSource={tableData} />

        <div style={{ marginTop: "20px" }}>
          <h2>Yıllık Toplam Giderler</h2>
          <Bar data={generateBarChartData(yearlyData)} />
        </div>
      </div>
    </Layout>
  );
};

export default GiderDetayPage;
