// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular-progress-bar {
    display: inline-block;
    width: 150px; /* Boyut büyütüldü */
    height: 150px; /* Boyut büyütüldü */
    position: relative;
    z-index: 1;
    margin-top: 20px; /* Konumu aşağı kaydırmak için margin-top eklendi */
  }
  
  .circular-progress-bar__background {
    fill: none;
    stroke: #f6f6f6;
    stroke-width: 10; /* Çember genişliği ayarlandı */
  }
  
  .circular-progress-bar__foreground {
    fill: none;
    stroke: url(#gradient);
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-width: 10; /* Çember genişliği ayarlandı */
  }
  
  .circular-progress-bar__text {
    position: absolute;
    top: 50%; /* Konum ayarlandı */
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px; /* Yazı boyutu büyütüldü */
    font-weight: bold;
    z-index: 2;
  }
  
  svg {
    display: block;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CircularProgressBar/CircularProgressBar.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY,EAAE,oBAAoB;IAClC,aAAa,EAAE,oBAAoB;IACnC,kBAAkB;IAClB,UAAU;IACV,gBAAgB,EAAE,mDAAmD;EACvE;;EAEA;IACE,UAAU;IACV,eAAe;IACf,gBAAgB,EAAE,+BAA+B;EACnD;;EAEA;IACE,UAAU;IACV,sBAAsB;IACtB,mCAAmC;IACnC,yBAAyB;IACzB,yBAAyB;IACzB,gBAAgB,EAAE,+BAA+B;EACnD;;EAEA;IACE,kBAAkB;IAClB,QAAQ,EAAE,oBAAoB;IAC9B,SAAS;IACT,gCAAgC;IAChC,eAAe,EAAE,0BAA0B;IAC3C,iBAAiB;IACjB,UAAU;EACZ;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".circular-progress-bar {\r\n    display: inline-block;\r\n    width: 150px; /* Boyut büyütüldü */\r\n    height: 150px; /* Boyut büyütüldü */\r\n    position: relative;\r\n    z-index: 1;\r\n    margin-top: 20px; /* Konumu aşağı kaydırmak için margin-top eklendi */\r\n  }\r\n  \r\n  .circular-progress-bar__background {\r\n    fill: none;\r\n    stroke: #f6f6f6;\r\n    stroke-width: 10; /* Çember genişliği ayarlandı */\r\n  }\r\n  \r\n  .circular-progress-bar__foreground {\r\n    fill: none;\r\n    stroke: url(#gradient);\r\n    transition: stroke-dashoffset 0.35s;\r\n    transform: rotate(-90deg);\r\n    transform-origin: 50% 50%;\r\n    stroke-width: 10; /* Çember genişliği ayarlandı */\r\n  }\r\n  \r\n  .circular-progress-bar__text {\r\n    position: absolute;\r\n    top: 50%; /* Konum ayarlandı */\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    font-size: 24px; /* Yazı boyutu büyütüldü */\r\n    font-weight: bold;\r\n    z-index: 2;\r\n  }\r\n  \r\n  svg {\r\n    display: block;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
