import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Login from "./components/LoginRegister/LoginRegister";
import LoginCorp from "./components/LoginRegister/loginCorporateUser";
import HomePage from "./pages/HomePage";
import ProtectedRoute from "./components/ProtectedRoute";
import SuspendedPage from "./pages/SuspendedPage";
import PublicRoute from "./components/PublicRoute";
import ActivateAccount from "./components/ActivateAccount";
import Gelir from "./pages/GelirPage/Gelir";
import Gider from "./pages/Gider";
import ResetPassword from "./components/ResetPassword";
import AddIncome from "./components/AddIncome";
import SuspendedRoute from "./components/SuspendedRoute";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Genel from "./pages/GenelPage/Genel";
import GelirDetayPage from "./pages/GelirDetay/GelirDetayPage";
import GiderDetayPage from "./pages/GiderDetay/GiderDetayPage";
import AdminPanel from './pages/Yonetim/AdminPanel';
import CorporateManagement from './pages/Yonetim/CorporateManagement';
import CorporateUserManagement from './pages/Yonetim/CorporateUserManagement';
import UserDataPage from "./pages/GelirPage/GelirVeriYonetimi";
import GiderListesi from "./pages/GiderVeriYonetimi";
function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/login-corp" element={<LoginCorp />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/activate/:token" element={<ActivateAccount />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/addIncome" element={<AddIncome />} />
          <Route path="/gelir" element={<Gelir />} />
          <Route path="/gider" element={<Gider />} />
          <Route path="/genel" element={<Genel />} />
          <Route path="/gelirDetay" element={<GelirDetayPage />} />
          <Route path="/giderDetay" element={<GiderDetayPage />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/corporate-management" element={<CorporateManagement />} />
          <Route path="/corporate-user-management" element={<CorporateUserManagement />} />
          <Route path="/gelir-edit" element={<UserDataPage />} />
          <Route path="/gider-edit" element={<GiderListesi />} />
        </Route>
        <Route element={<SuspendedRoute />}>
          <Route path="/suspended" element={<SuspendedPage />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
