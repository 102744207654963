import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

const GraphContainer = styled.div`
  margin-top: 20px;
  height: 400px;
  width: 100%; /* Tam genişlik */
  background: transparent;
  border-radius: 20px;
  padding: 20px;
  overflow-x: auto; /* Yatay kaydırma çubuğu ekler */
`;

const monthAbbreviations = [
  "Oca",
  "Şub",
  "Mar",
  "Nis",
  "May",
  "Haz",
  "Tem",
  "Ağu",
  "Eyl",
  "Eki",
  "Kas",
  "Ara",
];

const formatYAxis = (tickItem) => {
  if (tickItem >= 1000000) {
    return (tickItem / 1000000).toFixed(1) + "M";
  } else if (tickItem >= 1000) {
    return (tickItem / 1000).toFixed(1) + "K";
  }
  return tickItem.toString();
};

const GraphComponent = ({ data }) => {
  const chartData = Object.entries(data).map(([month, value]) => ({
    name: monthAbbreviations[parseInt(month) - 1], // Ay kısaltmalarını kullan
    value: value,
  }));

  return (
    <GraphContainer>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 5,
            }}
            style={{ background: "transparent" }}
          >
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#000000", fontSize: 14 }}
            />
            <YAxis
              axisLine={true}
              tickLine={true}
              tick={{ fill: "#000000", fontSize: 14 }} 
              tickFormatter={formatYAxis}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#333",
                borderColor: "#333",
                color: "#fff",
              }}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#ff7300"
              fill="#ff7300"
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <div>No data available</div>
      )}
    </GraphContainer>
  );
};

export default GraphComponent;
