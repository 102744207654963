import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { message as antdMessage, Button } from "antd";
import Layout from "../../Layout/Layout";
import { FaPlus } from "react-icons/fa";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import { Helmet } from "react-helmet";
import CommonModal from "../../components/CommonModal/CommonModal";
import FilterSection from "../../components/FilterSection/FilterSection";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Lazy load components
const Spinners = lazy(() => import("../../Layout/Spinners"));
const GraphComponent = lazy(() =>
  import("../../components/GraphComponents/GraphComponents")
);
const Parapara = lazy(() => import("../../components/Parapara/Parapara"));
const CircularProgressBar = lazy(() =>
  import("../../components/CircularProgressBar/CircularProgressBar")
);

const Gelir = () => {
  const navigate = useNavigate();
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalFilteredExpenditures, setTotalFilteredExpenditures] = useState(0);
  const [monthlyExpenditures, setMonthlyExpenditures] = useState([]);
  const [totalIncomeByCountry, setTotalIncomeByCountry] = useState({});
  const [totalIncomeByProje, setTotalIncomeByProje] = useState({});
  const [progress, setProgress] = useState(0);
  const [exchangeRates, setExchangeRates] = useState({});
  const [rates, setRates] = useState({});
  const [totalTutarFilteredExpenditures, setTutarTotalFilteredExpenditures] =
    useState(0);
  const [tutar, setTutar] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allGelir, setAllGelir] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  // Yeni state'leri ekleyin
  const [gelirTurleri, setGelirTurleri] = useState([]);
  const [faaliyetler, setFaaliyetler] = useState([]);
  const [kaynaklar, setKaynaklar] = useState([]);
  const [bolgeler, setBolgeler] = useState([]);

  const [formData, setFormData] = useState({
    gelirTarihi: "",
    ana_gelir_turu: "",
    faaliyet: "",
    kaynak: "",
    bolge: "",
    deger: "",
    doviz: "",
    tur: "",
    partner: "",
    aciklama: "",
    tutar: "",
  });

  const modalRef = useRef(); // Modal ref

  // Onay penceresi için state ve fonksiyonlar
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleConfirmationClose = () => setShowConfirmation(false);
  const handleConfirmationShow = () => setShowConfirmation(true);

  const turkishMonths = useMemo(
    () => [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    []
  );

   const getAllGelir = useCallback(async () => {
    try {
      setLoading(true);
      const res1 = await axios.post("/gelirs/get-gelir");
  
      const data = Array.isArray(res1.data) ? res1.data : [];
      console.log("Fetched Data:", data); // Geri dönen verileri kontrol edin
  
      setLoading(false);
      setAllGelir(data);
  
      if (data.length === 0) {
        antdMessage.info("Herhangi bir gelir kaydı bulunmuyor.");
        setTutar(0);
        return;
      }
  
      calculateMonthlyExpenditures(data);
  
      const totalTutar = data.reduce((sum, item) => {
        const tutar = typeof item.tutar === 'number' ? item.tutar : parseFloat(item.tutar.replace(",", "."));
        return sum + (isNaN(tutar) ? 0 : tutar);
      }, 0);
  
      setTutar(totalTutar);
    } catch (error) {
      console.error("Error fetching data:", error);
      antdMessage.error("Veri alınırken bir hata oluştu");
    }
  }, []);
  

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.tur) {
      antdMessage.error("Lütfen tür alanını doldurun");
      return;
    }
    handleConfirmationShow();
  };

  const confirmSubmit = async () => {
    const values = {
      tarih: new Date(formData.gelirTarihi),
      ana_gelir_turu: formData.ana_gelir_turu,
      faaliyet: formData.faaliyet,
      kaynak: formData.kaynak,
      bolge: formData.bolge,
      deger: formData.deger,
      tutar: formData.tutar,
      doviz: formData.doviz,
      tur: formData.tur,
      partner: formData.partner,
      aciklama: formData.aciklama,
    };

    setLoading(true);
    try {
      await axios.post("/gelirs/add-gelir", values);
      setStatusMessage("Veri Eklendi");
      console.log("Veri Eklendi mesajı ayarlandı"); // Konsola log ekleyin
      setTimeout(() => {
        setStatusMessage("");
        window.location.reload(); // Sayfanın yenilenmesi için
      }, 1000);
      setFormData({
        gelirTarihi: "",
        ana_gelir_turu: "",
        faaliyet: "",
        kaynak: "",
        bolge: "",
        deger: "",
        doviz: "",
        tur: "",
        partner: "",
        aciklama: "",
        tutar: "",
      });
      handleConfirmationClose();
      handleClose();
    } catch (error) {
      console.error("Error adding income:", error.response || error.message);
      setStatusMessage(
        "Error adding income: " +
          (error.response?.data?.message || error.message)
      );
      setTimeout(() => {
        setStatusMessage("");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const gelirTurleriRes = await axios.get("/gelirs/gelir-turleri");
        console.log("Gelir Türleri:", gelirTurleriRes.data); // Konsola log ekleyin
        setGelirTurleri(gelirTurleriRes.data);

        const faaliyetlerRes = await axios.get("/gelirs/faaliyetler");
        console.log("Faaliyetler:", faaliyetlerRes.data); // Konsola log ekleyin
        setFaaliyetler(faaliyetlerRes.data);

        const kaynaklarRes = await axios.get("/gelirs/kaynaklar");
        console.log("Kaynaklar:", kaynaklarRes.data); // Konsola log ekleyin
        setKaynaklar(kaynaklarRes.data);

        const bolgelerRes = await axios.get("/gelirs/bolgeler");
        console.log("Bölgeler:", bolgelerRes.data); // Konsola log ekleyin
        setBolgeler(bolgelerRes.data);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, []);

  useEffect(() => {
    // Döviz kurlarını API'den çek
    fetch("https://api.exchangerate-api.com/v4/latest/USD")
      .then((response) => response.json())
      .then((data) => {
        // USD ve EUR'nin TL cinsinden karşılıklarını hesapla
        const usdToTry = data.rates.TRY;
        const eurToTry = usdToTry / data.rates.EUR;
        const gbpToTry = usdToTry / data.rates.GBP;

        // Diğer döviz kurlarını TL cinsinden güncelle
        setRates({
          USD: usdToTry,
          EUR: eurToTry,
          GBP: gbpToTry,
          TRY: 1, // TL'nin kendi değeri 1'dir
        });
        // Güncellenmiş exchangeRates'i konsola yazdır
        console.log("Güncellenmiş 1:", {
          USD: usdToTry,
          EUR: eurToTry,
          GBP: gbpToTry,
          TRY: 1,
        });
      })
      .catch((error) => {
        console.error("Error fetching exchange rates:", error);
      });
  }, []);

  useEffect(() => {
    console.log("Güncellenmiş 2:", rates);
  }, [rates]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "addNew") {
      const newOption = prompt("Yeni bir seçenek ekleyin:");
      if (newOption) {
        if (name === "ana_gelir_turu") {
          setGelirTurleri([...gelirTurleri, newOption]);
          setFormData({ ...formData, [name]: newOption });
        } else if (name === "faaliyet") {
          setFaaliyetler([...faaliyetler, newOption]);
          setFormData({ ...formData, [name]: newOption });
        } else if (name === "kaynak") {
          setKaynaklar([...kaynaklar, newOption]);
          setFormData({ ...formData, [name]: newOption });
        } else if (name === "bolge") {
          setBolgeler([...bolgeler, newOption]);
          setFormData({ ...formData, [name]: newOption });
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (formData.deger && formData.doviz) {
      const deger = parseFloat(formData.deger);
      const kur = rates[formData.doviz];
      if (deger && kur) {
        const tutar = deger * kur;
        setFormData((prevData) => ({
          ...prevData,
          tutar: tutar.toFixed(2),
        }));
      }
    }
  }, [formData.deger, formData.doviz, rates]);

  useEffect(() => {
    getAllGelir();
  }, [getAllGelir]);

  useEffect(() => {
    updateFilters();
  }, [selectedYears, selectedMonths, selectedCountries, allGelir]);

  useEffect(() => {
    const monthlyExpendituresData = calculateMonthlyExpenditures(filteredData);
    setMonthlyExpenditures(monthlyExpendituresData);
  }, [filteredData]);

  useEffect(() => {
    if (totalTutarFilteredExpenditures > 0) {
      const progressValue = (
        (totalTutarFilteredExpenditures / tutar) *
        100
      ).toFixed(2);
      setProgress(progressValue);
    }
  }, [tutar, totalTutarFilteredExpenditures]);

  const calculateMonthlyExpenditures = (data) => {
    const monthlyExpendituresData = {};
    data.forEach((item) => {
      const month = item.ay;
      const amount = parseFloat(item.tutar.replace(",", "."));
      if (!monthlyExpendituresData[month]) {
        monthlyExpendituresData[month] = 0;
      }
      monthlyExpendituresData[month] += amount;
    });

    return monthlyExpendituresData;
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const calculateTotalIncomeByExchange = (data) => {
    const exchangeRates = {};

    data.forEach((item) => {
      const doviz = item.doviz;
      const income = parseFloat(item.deger.replace(",", "."));
      if (!exchangeRates[doviz]) {
        exchangeRates[doviz] = 0;
      }
      exchangeRates[doviz] += income;
    });

    setExchangeRates(exchangeRates);
  };

  const calculateTotalIncomeByCountryAndDisplay = (data) => {
    const totalIncomeByCountry = {};

    data.forEach((item) => {
      const country = item.bolge;
      const income = parseFloat(item.tutar.replace(",", "."));
      if (!totalIncomeByCountry[country]) {
        totalIncomeByCountry[country] = 0;
      }
      totalIncomeByCountry[country] += income;
    });
    setTotalIncomeByCountry(totalIncomeByCountry);
  };

  const calculateTotalIncomeByProjeAndDisplay = (data) => {
    const totalIncomeByProje = {};

    data.forEach((item) => {
      const faaliyet = item.faaliyet;
      const faincome = parseFloat(item.tutar.replace(",", "."));
      if (!totalIncomeByProje[faaliyet]) {
        totalIncomeByProje[faaliyet] = 0;
      }
      totalIncomeByProje[faaliyet] += faincome;
    });
    setTotalIncomeByProje(totalIncomeByProje);
  };

  const formatNumberShort = (value) => {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(2) + "B";
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return value / 1e3 + "K";
    } else {
      return value.toFixed(2);
    }
  };

  const updateFilters = useCallback(() => {
    const filteredData = allGelir.filter(
      (item) =>
        (selectedYears.length === 0 || selectedYears.includes(item.yil)) &&
        (selectedMonths.length === 0 || selectedMonths.includes(item.ay)) &&
        (selectedCountries.length === 0 ||
          selectedCountries.includes(item.bolge))
    );

    setFilteredData(filteredData);

    const totalExpenditures = filteredData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );
    const totaltutarExpenditures = filteredData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );
    setTutarTotalFilteredExpenditures(totaltutarExpenditures);
    setTotalFilteredExpenditures(totalExpenditures);

    const years = [
      ...new Set(
        allGelir
          .filter(
            (item) =>
              (selectedMonths.length === 0 ||
                selectedMonths.includes(item.ay)) &&
              (selectedCountries.length === 0 ||
                selectedCountries.includes(item.bolge))
          )
          .map((item) => item.yil)
      ),
    ].sort((a, b) => a - b);
    const months = [
      ...new Set(
        allGelir
          .filter(
            (item) =>
              (selectedYears.length === 0 ||
                selectedYears.includes(item.yil)) &&
              (selectedCountries.length === 0 ||
                selectedCountries.includes(item.bolge))
          )
          .map((item) => item.ay)
      ),
    ].sort((a, b) => a - b);
    const countries = [
      ...new Set(
        allGelir
          .filter(
            (item) =>
              (selectedYears.length === 0 ||
                selectedYears.includes(item.yil)) &&
              (selectedMonths.length === 0 || selectedMonths.includes(item.ay))
          )
          .map((item) => item.bolge)
      ),
    ];

    setAvailableYears(years);
    setAvailableMonths(months);
    setAvailableCountries(countries);
    calculateTotalIncomeByCountryAndDisplay(filteredData);
    calculateTotalIncomeByProjeAndDisplay(filteredData);
    calculateTotalIncomeByExchange(filteredData);

    setLoading(false);
  }, [allGelir, selectedYears, selectedMonths, selectedCountries]);

  const formFields = [
    {
      name: "gelirTarihi",
      label: "Gelir Tarihi",
      type: "date",
      required: true,
    },
    {
      name: "ana_gelir_turu",
      label: "Ana Gelir Türü",
      type: "text",
      required: true,
    },
    {
      name: "faaliyet",
      label: "Faaliyet",
      type: "text",
      required: true,
      placeholder: "Faaliyet giriniz",
    },
    {
      name: "kaynak",
      label: "Kaynak",
      type: "text",
      required: true,
      placeholder: "Kaynak giriniz",
    },
    {
      name: "bolge",
      label: "Bölge",
      type: "text",
      required: true,
      placeholder: "Bölge ismi giriniz",
    },
    {
      name: "deger",
      label: "Değer",
      type: "number",
      required: true,
      placeholder: "Değer miktarı girin",
    },
    {
      name: "doviz",
      label: "Döviz",
      type: "select",
      required: true,
      options: [
        { value: "", label: "Seçiniz" }, // Bu varsayılan değer olmamalı, kullanıcı bir değer seçmeli
        { value: "TRY", label: "TL" },
        { value: "EUR", label: "EUR" },
        { value: "USD", label: "USD" },
        { value: "GBP", label: "GBP" },
      ],
    },
    {
      name: "tutar",
      label: "Tutar",
      type: "text",
      readOnly: true,
      placeholder: "Tutar otomatik hesaplanacak",
    },

    {
      name: "tur",
      label: "Tür",
      type: "select",
      required: true,
      options: [
        { value: "Seçiniz", label: "Seçiniz" },
        { value: "Nakit", label: "Nakit" },
        { value: "Kredi", label: "Kredi" },
        { value: "Banka", label: "Banka" },
      ],
    },
    {
      name: "partner",
      label: "Partner",
      type: "text",
      placeholder: "Partner girin",
    },
    {
      name: "aciklama",
      label: "Açıklama",
      type: "textarea",
      required: true,
      placeholder: "Açıklama girin",
    },
  ];

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      antdMessage.error("Lütfen bir dosya seçin");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post("/import/import-gelir", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        antdMessage.success("Dosya başarıyla yüklendi");
      }
    } catch (error) {
      antdMessage.error("Dosya yüklenirken hata oluştu");
      console.error("Upload error:", error);
    }
  };

  const handleExport = async () => {
    try {
      const response = await axios.get("/exports/export-gelir", {
        responseType: "blob", // Bu önemli, dosyayı blob olarak almak için
      });

      // Blob'dan bir URL oluşturun
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Gelirler.xlsx"); // Dosya adı
      document.body.appendChild(link);
      link.click();

      antdMessage.success("Gelirler başarıyla dışa aktarıldı");
    } catch (error) {
      console.error("Gelirler dışa aktarma hatası:", error);
      antdMessage.error("Gelirler dışa aktarırken bir hata oluştu");
    }
  };
  const handleDownload = async () => {
    try {
      const response = await axios({
        url: '/download/gelir-template',
        method: 'GET',
        responseType: 'blob', // Blob olarak yanıt alın
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'gelir_template.xlsx'); // Dosya adı
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Dosya indirilirken hata:', error);
    }
  };
  const handleRedirect = () => {
    window.open('https://www.youtube.com/watch?v=YourVideoID', '_blank');
  };

  return (
    <Layout>
      <>
        <Helmet>
          <title>Gelir Modülü - Finansal Yönetim</title>
          <meta
            name="description"
            content="Gelirlerin yönetimi ve analizi için modül."
          />
        </Helmet>
        {loading && (
          <Suspense fallback={<div>Loading...</div>}>
            <Spinners />
          </Suspense>
        )}
        <Container fluid>
          <Row className="mb-3">
            <Col xs={12} md={5} className="adjusted-pad">
              <Row>
                <div className="custom-boxs">
                  <div className="top-section">
                    <Box className="styled-boxs">
                      <Typography variant="h6" className="box-titles">
                        Gelir Modülü
                      </Typography>
                    </Box>
                    <Typography variant="body1" className="box-descriptions">
                      Bu kısımda kurumun mevcut gelir durumu ve gelirlerin
                      çeşitli alt kalemlere göre dağılımları görülmektedir.
                      Yönetici ilgili dağılımları inceleyerek gerekli stratejik
                      planlamayı yapabilir.
                    </Typography>
                    <div className="plus-button-container" onClick={handleShow}>
                      <FaPlus className="plus-button" />
                      <span className="tooltip-text">Veri Girişi</span>
                    </div>
                    <div>
                      <h2>Excel Dosyası Yükle</h2>
                      <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                      />
                      <button onClick={handleUpload}>Yükle</button>
                      <Button type="primary" onClick={handleExport}>
                        Gelirler Dışa Aktar
                      </Button>
                      <Button type="primary" color="red" onClick={handleDownload}>
                        Excel Şablonunu İndir
                      </Button>
                      <Button type="primary" onClick={handleRedirect}>
                      İmport işlemi nasıl yapılır
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
            <Col md={1}></Col>
            <Col xs={12} md={6}>
              <Box className="styled-box">
                <Typography variant="h2">Gelir</Typography>
                <div className="divider"></div>
                <div className="styled-value">
                  {totalTutarFilteredExpenditures.toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  <CurrencyLiraIcon className="styled-currency" />
                </div>
                <div className="mb-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CircularProgressBar progress={progress} />
                  </Suspense>
                </div>
              </Box>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4} className="adjusted-top">
              <FilterSection
                availableYears={availableYears}
                availableMonths={availableMonths}
                availableCountries={availableCountries}
                setSelectedYears={setSelectedYears}
                setSelectedMonths={setSelectedMonths}
                setSelectedCountries={setSelectedCountries}
                turkishMonths={turkishMonths}
              />
            </Col>
            <Col xs={12} md={8}>
              <Suspense fallback={<div>Loading...</div>}>
                <Parapara
                  totalFilteredExpenditures={totalFilteredExpenditures}
                  calculateTotalIncomeByExchange={exchangeRates}
                />
              </Suspense>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={5}>
              <div className="custom-box">
                <h3 className="custom-header">Country-wise Income</h3>
                <div>
                  <Table className="custom-table">
                    <tbody>
                      {Object.entries(totalIncomeByCountry).map(
                        ([country, income], index) => {
                          const incomeRatio = (
                            (income / totalFilteredExpenditures) *
                            100
                          ).toFixed(2);
                          return (
                            <tr key={index}>
                              <td
                                style={{ width: "1px", whiteSpace: "nowrap" }}
                              >
                                &#8226; {/* Nokta işareti */}
                              </td>
                              <td>{country}</td>
                              <td>{income.toFixed(2)} ₺</td>
                              <td>{incomeRatio}%</td> {/* Yüzdelik sütunu */}
                              <td>
                                <div className="table-progress-bar">
                                  <div
                                    className="table-progress"
                                    style={{ width: `${incomeRatio}%` }}
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="graph-container">
                  <Suspense fallback={<div>Loading...</div>}>
                    <GraphComponent data={monthlyExpenditures} />
                  </Suspense>
                </div>
              </div>
            </Col>
            <Col md={1}></Col>
            <Col md={6}>
              <div className="custom-box">
                <h3 className="custom-header">Proje-wise Income</h3>
                <div className="custom-table-container">
                  <Table className="custom-table">
                    <tbody>
                      {Object.entries(totalIncomeByProje).map(
                        ([faaliyet, faincome], index) => {
                          const incomeRatio = (
                            (faincome / totalFilteredExpenditures) *
                            100
                          ).toFixed(2);
                          return (
                            <tr key={index}>
                              <td
                                style={{ width: "1px", whiteSpace: "nowrap" }}
                              >
                                &#8226; {/* Nokta işareti */}
                              </td>
                              <td>{faaliyet}</td>
                              <td>{formatNumberShort(faincome)} ₺</td>
                              <td>{incomeRatio}%</td> {/* Yüzdelik sütunu */}
                              <td>
                                <div className="table-progress-bar">
                                  <div
                                    className="table-progress"
                                    style={{ width: `${incomeRatio}%` }}
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <CommonModal
          ref={modalRef}
          showModal={showModal}
          handleClose={handleClose}
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          getCurrentDate={getCurrentDate}
          statusMessage={statusMessage}
          loading={loading}
          formFields={formFields}
          showConfirmation={showConfirmation}
          handleConfirmationClose={handleConfirmationClose}
          confirmSubmit={confirmSubmit}
          gelirTurleri={gelirTurleri}
          faaliyetler={faaliyetler}
          kaynaklar={kaynaklar}
          bolgeler={bolgeler}
        />
      </>
    </Layout>
  );
};

export default Gelir;
