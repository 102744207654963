import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EuroIcon from "@mui/icons-material/Euro";
import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import "./Parapara.css";

const currencyIcons = {
  USD: <AttachMoneyIcon fontSize="large" />,
  EUR: <EuroIcon fontSize="large" />,
  TL: <CurrencyLiraIcon fontSize="large" />,
  GBP: <Typography style={{ fontSize: "2rem" }}>£</Typography>, // Pound simgesi
};

const defaultCurrencies = ["USD", "EUR", "TL", "GBP"];

// API'den gelen değerleri frontend'de kullanmak için dönüşüm tablosu
const currencyMapping = {
  USD: "USD",
  EUR: "EUR",
  TL: "TRY", // API'den TRY olarak gelen değeri TL olarak kullanacağız
  GBP: "GBP",
};

function Parapara({ calculateTotalIncomeByExchange}) {
  const [maxCurrency, setMaxCurrency] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({});
  const [totalInTL, setTotalInTL] = useState(0);

  useEffect(() => {
    // Döviz kurlarını API'den çek
    fetch("https://api.exchangerate-api.com/v4/latest/USD")
      .then((response) => response.json())
      .then((data) => {
        // USD ve EUR'nin TL cinsinden karşılıklarını hesapla
        const usdToTry = data.rates.TRY;
        const eurToTry = usdToTry / data.rates.EUR;
        const gbpToTry = usdToTry / data.rates.GBP;

        // Diğer döviz kurlarını TL cinsinden güncelle
        setExchangeRates({
          USD: usdToTry,
          EUR: eurToTry,
          GBP: gbpToTry,
          TRY: 1, // TL'nin kendi değeri 1'dir
        });
      })
      .catch((error) => {
        console.error("Error fetching exchange rates:", error);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(exchangeRates).length > 0) {
      const total = defaultCurrencies.reduce((sum, currency) => {
        const currencyValue =
          calculateTotalIncomeByExchange[currencyMapping[currency]] || 0;
        const exchangeRate = exchangeRates[currencyMapping[currency]] || 1;
        return sum + currencyValue * exchangeRate;
      }, 0);

      setTotalInTL(total);
      

      const maxCurrency = defaultCurrencies.reduce((prev, current) => {
        const prevValue =
          (calculateTotalIncomeByExchange[currencyMapping[prev]] || 0) *
          (exchangeRates[currencyMapping[prev]] || 1);
        const currentValue =
          (calculateTotalIncomeByExchange[currencyMapping[current]] || 0) *
          (exchangeRates[currencyMapping[current]] || 1);
        return prevValue > currentValue ? prev : current;
      }, defaultCurrencies[0]);

      setMaxCurrency(maxCurrency);
    }
  }, [exchangeRates, calculateTotalIncomeByExchange]);

  return (
    <Box className="parapara-container">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {defaultCurrencies.map((currency) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={currency}>
            <Box
              className={`currency-card ${
                currency === maxCurrency ? "highlight" : ""
              }`}
            >
              <Box className="currency-icon">{currencyIcons[currency]}</Box>
              <Typography
                variant="h6"
                component="div"
                align="center"
                className="currency-amount"
              >
                {(
                  calculateTotalIncomeByExchange[currencyMapping[currency]] || 0
                ).toLocaleString("en-US", { minimumFractionDigits: 2 })}{" "}
                
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Parapara;
