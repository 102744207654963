import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../Layout/Layout";

const CorporateUserManagement = () => {
  const [corporateUsers, setCorporateUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [corporateId, setCorporateId] = useState('');
  const [corporates, setCorporates] = useState([]); // Kurumları depolamak için state

  useEffect(() => {
    const fetchCorporateUsers = async () => {
      try {
        const res = await axios.get('/corporate/get-corporate-users', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCorporateUsers(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCorporateUsers();

    const fetchCorporates = async () => {
      try {
        const res = await axios.get('/corporate/get-corporates', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCorporates(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCorporates();
  }, []);

  const addCorporateUser = async () => {
    try {
      const res = await axios.post('/corporate/add-corporate-user', {
        username, email, password, corporateId
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCorporateUsers([...corporateUsers, res.data]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
    <div>
      <h1>Kurumsal Kullanıcı Yönetimi</h1>
      <input
        type="text"
        placeholder="Kullanıcı Adı"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Şifre"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <select
        value={corporateId}
        onChange={(e) => setCorporateId(e.target.value)}
      >
        <option value="">Kurum Seçin</option>
        {corporates.map(corporate => (
          <option key={corporate._id} value={corporate._id}>
            {corporate.name}
          </option>
        ))}
      </select>
      <button onClick={addCorporateUser}>Kurumsal Kullanıcı Ekle</button>
      <ul>
  {Array.isArray(corporateUsers) && corporateUsers.map(user => (
    <li key={user._id}>{user.username} - {user.email}</li>
  ))}
</ul>

    </div>
    </Layout>
  );
};

export default CorporateUserManagement;
