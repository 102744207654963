// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.content{
  height: 87vh;
}

.navbar{
  background-color: gray !important ;
}

.register-page{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

/* transaction page*/
.filters{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  box-shadow: 0 0 3px gray;
}
/* transaction page*/


.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA,oBAAoB;AACpB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,wBAAwB;AAC1B;AACA,oBAAoB;;;AAGpB;EACE,yBAAyB,EAAE,eAAe;EAC1C,6BAA6B,EAAE,SAAS;EACxC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":["*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n\n.content{\n  height: 87vh;\n}\n\n.navbar{\n  background-color: gray !important ;\n}\n\n.register-page{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 90vh;\n}\n\n/* transaction page*/\n.filters{\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 15px 20px;\n  box-shadow: 0 0 3px gray;\n}\n/* transaction page*/\n\n\n.loader {\n  border: 4px solid #f3f3f3; /* Light grey */\n  border-top: 4px solid #3498db; /* Blue */\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
