import React from 'react';
import Header from './Header';
import Footer from './Footer';

const layoutStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
};

const contentStyle = {
  flex: 1,
};

const Layout = ({ children }) => {
  return (
    <div style={layoutStyle}>
      <Header />
      <div style={contentStyle}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
