import React, { useState } from "react";
import axios from "axios";
import { FaEnvelope } from "react-icons/fa";
import "./ForgotPassword.css"; // Sadece ForgotPassword.css dosyasını kullanıyoruz

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("/auth/forgotPassword", { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || "Error sending email");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-page">
      <div className="wrapper">
        <div className="form-box forgot-password">
          <h1>Forgot Password</h1>
          {message && <div className="alert-message">{message}</div>}
          <form onSubmit={handleSubmit} className="custom-form">
            <div className="input-box">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <FaEnvelope className="icon" />
            </div>
            <button type="submit" className="custom-button" disabled={loading}>
              {loading ? "Sending..." : "Send Reset Email"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
