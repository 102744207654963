import Layout from '../Layout/Layout';
import React from 'react';

const SuspendedPage = () => {
    return (
        <Layout>
        <div className='Suspended'>
            <h1>Hesabınız askıya alındı</h1>
            <p>Destek için iletişime geçin.</p>
        </div>
        </Layout>
    );
};

export default SuspendedPage;
