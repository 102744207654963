import React from 'react';
import { Link } from 'react-router-dom';

const AdminPanel = () => {
  return (
    <div>
      <h1>Admin Panel</h1>
      <ul>
        <li>
          <Link to="/corporate-management">Kurum Yönetimi</Link>
        </li>
        <li>
          <Link to="/corporate-user-management">Kurumsal Kullanıcı Yönetimi</Link>
        </li>
        {/* Diğer yönetim bağlantıları */}
      </ul>
    </div>
  );
};

export default AdminPanel;
