import React, { useEffect, useState } from "react";
import axios from "axios";
import { message as antdMessage } from "antd";
import { Button, Row, Col, Card, Container } from "react-bootstrap";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import Layout from "../../Layout/Layout";
import "./Genel.css"; // seçili butonlar için CSS ekleyin
import { ThemeProvider, createTheme } from "@mui/material/styles";

const Genel = () => {
  const [allGelir, setAllGelir] = useState([]);
  const [allGider, setAllGider] = useState([]);
  const [filteredGelir, setFilteredGelir] = useState([]);
  const [filteredGider, setFilteredGider] = useState([]);
  const [totalGelir, setTotalGelir] = useState(0);
  const [totalGider, setTotalGider] = useState(0);
  const [netTutar, setNetTutar] = useState(0);
  const [loading, setLoading] = useState(false);
  const [topDovizValues, setTopDovizValues] = useState([]);
  const [selectedGelirCountries, setSelectedGelirCountries] = useState([]);
  const [selectedGiderCountries, setSelectedGiderCountries] = useState([]);
  const [selectedGelirYears, setSelectedGelirYears] = useState([]);
  const [selectedGiderYears, setSelectedGiderYears] = useState([]);
  const [selectedGelirMonths, setSelectedGelirMonths] = useState([]);
  const [selectedGiderMonths, setSelectedGiderMonths] = useState([]);
  const [availableGelirCountries, setAvailableGelirCountries] = useState([]);
  const [availableGiderCountries, setAvailableGiderCountries] = useState([]);
  const [availableGelirYears, setAvailableGelirYears] = useState([]);
  const [availableGiderYears, setAvailableGiderYears] = useState([]);
  const [availableGelirMonths, setAvailableGelirMonths] = useState([]);
  const [availableGiderMonths, setAvailableGiderMonths] = useState([]);
  const [rates, setRates] = useState({});

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#dc004e",
      },
    },
  });
  const fetchData = async () => {
    try {
      setLoading(true);
      const res1 = await axios.post("/gelirs/get-gelir");
      const res2 = await axios.post("/giders/get-gider");

      setAllGelir(res1.data);
      setAllGider(res2.data);
      setFilteredGelir(res1.data); // Tüm verileri başlangıçta set edin
      setFilteredGider(res2.data); // Tüm verileri başlangıçta set edin
      extractUniqueValues(res1.data, res2.data);

      // Filtreleri başlangıçta tüm verilerle güncelleyin
      updateGelirFilters(res1.data);
      updateGiderFilters(res2.data);

      // Toplam gelir ve gideri hesapla
      calculateTotals(res1.data, res2.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      antdMessage.error("Veri alınırken bir hata oluştu");
      setLoading(false);
    }
  };

  useEffect(() => {
    // Döviz kurlarını API'den çek
    fetch("https://api.exchangerate-api.com/v4/latest/USD")
      .then((response) => response.json())
      .then((data) => {
        // USD ve EUR'nin TL cinsinden karşılıklarını hesapla
        const usdToTry = data.rates.TRY;
        const eurToTry = usdToTry / data.rates.EUR;
        const gbpToTry = usdToTry / data.rates.GBP;

        // Diğer döviz kurlarını TL cinsinden güncelle
        setRates({
          USD: usdToTry,
          EUR: eurToTry,
          GBP: gbpToTry,
          TRY: 1, // TL'nin kendi değeri 1'dir
        });
        // Güncellenmiş exchangeRates'i konsola yazdır
        console.log("Güncellenmiş 1:", {
          USD: usdToTry,
          EUR: eurToTry,
          GBP: gbpToTry,
          TRY: 1,
        });
      })
      .catch((error) => {
        console.error("Error fetching exchange rates:", error);
      });
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    updateGelirFilters(allGelir);
  }, [selectedGelirCountries, selectedGelirYears, selectedGelirMonths]);

  useEffect(() => {
    updateGiderFilters(allGider);
  }, [selectedGiderCountries, selectedGiderYears, selectedGiderMonths]);

  const calculateTotals = (gelirData, giderData) => {
    const totalGelir = gelirData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );

    const totalGider = giderData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(",", ".")),
      0
    );

    const netTutar = totalGelir - totalGider;

    setTotalGelir(totalGelir);
    setTotalGider(totalGider);
    setNetTutar(netTutar);

    calculateDovizTotals(gelirData, giderData);
  };

  const calculateDovizTotals = (gelirData, giderData) => {
    const dovizGelirToplamlari = {};
    const dovizGiderToplamlari = {};

    gelirData.forEach((item) => {
      const doviz = item.doviz;
      const tutar = parseFloat(item.tutar.replace(",", "."));
      if (dovizGelirToplamlari[doviz]) {
        dovizGelirToplamlari[doviz] += tutar;
      } else {
        dovizGelirToplamlari[doviz] = tutar;
      }
    });

    giderData.forEach((item) => {
      const doviz = item.doviz;
      const tutar = parseFloat(item.tutar.replace(",", "."));
      if (dovizGiderToplamlari[doviz]) {
        dovizGiderToplamlari[doviz] += tutar;
      } else {
        dovizGiderToplamlari[doviz] = tutar;
      }
    });

    const sortedDovizValues = Object.keys(dovizGelirToplamlari)
      .concat(Object.keys(dovizGiderToplamlari))
      .reduce((acc, doviz) => {
        if (!acc.includes(doviz)) acc.push(doviz);
        return acc;
      }, []);

    const dovizNetToplamlari = {};
    sortedDovizValues.forEach((doviz) => {
      const gelir = dovizGelirToplamlari[doviz] || 0;
      const gider = dovizGiderToplamlari[doviz] || 0;
      dovizNetToplamlari[doviz] = {
        gelir: gelir,
        gider: gider,
        net: gelir - gider,
      };
    });

    setTopDovizValues(dovizNetToplamlari);
  };

  const extractUniqueValues = (gelirData, giderData) => {
    const uniqueGelirCountries = [
      ...new Set(gelirData.map((item) => item.bolge)),
    ];
    const uniqueGiderCountries = [
      ...new Set(giderData.map((item) => item.bolge)),
    ];
    const uniqueGelirYears = [...new Set(gelirData.map((item) => item.yil))];
    const uniqueGiderYears = [...new Set(giderData.map((item) => item.yil))];
    const uniqueGelirMonths = [
      ...new Set(gelirData.map((item) => monthNumberToName(item.ay))),
    ].sort((a, b) => {
      const monthsOrder = [
        "Oca",
        "Şub",
        "Mar",
        "Nis",
        "May",
        "Haz",
        "Tem",
        "Ağu",
        "Eyl",
        "Eki",
        "Kas",
        "Ara",
      ];
      return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
    });
    const uniqueGiderMonths = [
      ...new Set(giderData.map((item) => monthNumberToName(item.ay))),
    ].sort((a, b) => {
      const monthsOrder = [
        "Oca",
        "Şub",
        "Mar",
        "Nis",
        "May",
        "Haz",
        "Tem",
        "Ağu",
        "Eyl",
        "Eki",
        "Kas",
        "Ara",
      ];
      return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
    });

    setAvailableGelirCountries(uniqueGelirCountries);
    setAvailableGiderCountries(uniqueGiderCountries);
    setAvailableGelirYears(uniqueGelirYears);
    setAvailableGiderYears(uniqueGiderYears);
    setAvailableGelirMonths(uniqueGelirMonths);
    setAvailableGiderMonths(uniqueGiderMonths);
  };
  const updateGelirFilters = (gelirData = allGelir) => {
    if (!gelirData) return;

    const filteredData = gelirData.filter(
      (item) =>
        (selectedGelirYears.length === 0 ||
          selectedGelirYears.includes(item.yil)) &&
        (selectedGelirMonths.length === 0 ||
          selectedGelirMonths.includes(monthNumberToName(item.ay))) &&
        (selectedGelirCountries.length === 0 ||
          selectedGelirCountries.includes(item.bolge))
    );

    setFilteredGelir(filteredData);
    calculateTotals(filteredData, filteredGider);

    setAvailableGelirYears([
      ...new Set(
        gelirData
          .filter(
            (item) =>
              (selectedGelirMonths.length === 0 ||
                selectedGelirMonths.includes(monthNumberToName(item.ay))) &&
              (selectedGelirCountries.length === 0 ||
                selectedGelirCountries.includes(item.bolge))
          )
          .map((item) => item.yil)
      ),
    ]);
    setAvailableGelirMonths([
      ...new Set(
        gelirData
          .filter(
            (item) =>
              (selectedGelirYears.length === 0 ||
                selectedGelirYears.includes(item.yil)) &&
              (selectedGelirCountries.length === 0 ||
                selectedGelirCountries.includes(item.bolge))
          )
          .map((item) => monthNumberToName(item.ay))
      ),
    ]);
    setAvailableGelirCountries([
      ...new Set(
        gelirData
          .filter(
            (item) =>
              (selectedGelirYears.length === 0 ||
                selectedGelirYears.includes(item.yil)) &&
              (selectedGelirMonths.length === 0 ||
                selectedGelirMonths.includes(monthNumberToName(item.ay)))
          )
          .map((item) => item.bolge)
      ),
    ]);
  };

  const updateGiderFilters = (giderData) => {
    if (!giderData) return;

    const filteredData = giderData.filter(
      (item) =>
        (selectedGiderYears.length === 0 ||
          selectedGiderYears.includes(item.yil)) &&
        (selectedGiderMonths.length === 0 ||
          selectedGiderMonths.includes(monthNumberToName(item.ay))) &&
        (selectedGiderCountries.length === 0 ||
          selectedGiderCountries.includes(item.bolge))
    );

    setFilteredGider(filteredData);
    calculateTotals(filteredGelir, filteredData);

    setAvailableGiderYears([
      ...new Set(
        giderData
          .filter(
            (item) =>
              (selectedGiderMonths.length === 0 ||
                selectedGiderMonths.includes(monthNumberToName(item.ay))) &&
              (selectedGiderCountries.length === 0 ||
                selectedGiderCountries.includes(item.bolge))
          )
          .map((item) => item.yil)
      ),
    ]);
    setAvailableGiderMonths([
      ...new Set(
        giderData
          .filter(
            (item) =>
              (selectedGiderYears.length === 0 ||
                selectedGiderYears.includes(item.yil)) &&
              (selectedGiderCountries.length === 0 ||
                selectedGiderCountries.includes(item.bolge))
          )
          .map((item) => monthNumberToName(item.ay))
      ),
    ]);
    setAvailableGiderCountries([
      ...new Set(
        giderData
          .filter(
            (item) =>
              (selectedGiderYears.length === 0 ||
                selectedGiderYears.includes(item.yil)) &&
              (selectedGiderMonths.length === 0 ||
                selectedGiderMonths.includes(monthNumberToName(item.ay)))
          )
          .map((item) => item.bolge)
      ),
    ]);
  };
  const toggleGelirSelection = (category, value) => {
    switch (category) {
      case "Countries":
        setSelectedGelirCountries((prev) => {
          if (prev.includes(value)) {
            return prev.filter((item) => item !== value);
          } else {
            return [...prev, value];
          }
        });
        break;
      case "Years":
        setSelectedGelirYears((prev) => {
          if (prev.includes(value)) {
            return prev.filter((item) => item !== value);
          } else {
            return [...prev, value];
          }
        });
        break;
      case "Months":
        setSelectedGelirMonths((prev) => {
          if (prev.includes(value)) {
            return prev.filter((item) => item !== value);
          } else {
            return [...prev, value];
          }
        });
        break;
      default:
        break;
    }
  };

  const toggleGiderSelection = (category, value) => {
    switch (category) {
      case "Countries":
        setSelectedGiderCountries((prev) => {
          if (prev.includes(value)) {
            return prev.filter((item) => item !== value);
          } else {
            return [...prev, value];
          }
        });
        break;
      case "Years":
        setSelectedGiderYears((prev) => {
          if (prev.includes(value)) {
            return prev.filter((item) => item !== value);
          } else {
            return [...prev, value];
          }
        });
        break;
      case "Months":
        setSelectedGiderMonths((prev) => {
          if (prev.includes(value)) {
            return prev.filter((item) => item !== value);
          } else {
            return [...prev, value];
          }
        });
        break;
      default:
        break;
    }
  };

  const monthNumberToName = (monthNumber) => {
    const months = [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ];
    return months[monthNumber - 1]; // Ay numaraları 1-12 arasında olduğu varsayıldı.
  };

  const clearSelection = (category, type) => {
    if (type === "gelir") {
      switch (category) {
        case "Countries":
          setSelectedGelirCountries([]);
          break;
        case "Years":
          setSelectedGelirYears([]);
          break;
        case "Months":
          setSelectedGelirMonths([]);
          break;
        default:
          break;
      }
    } else if (type === "gider") {
      switch (category) {
        case "Countries":
          setSelectedGiderCountries([]);
          break;
        case "Years":
          setSelectedGiderYears([]);
          break;
        case "Months":
          setSelectedGiderMonths([]);
          break;
        default:
          break;
      }
    }
  };

  const selectAll = (category, type) => {
    if (type === "gelir") {
      switch (category) {
        case "Countries":
          setSelectedGelirCountries([
            ...new Set(allGelir.map((item) => item.bolge)),
          ]);
          break;
        case "Years":
          setSelectedGelirYears([...new Set(allGelir.map((item) => item.yil))]);
          break;
        case "Months":
          setSelectedGelirMonths(
            [
              ...new Set(allGelir.map((item) => monthNumberToName(item.ay))),
            ].sort((a, b) => {
              const monthsOrder = [
                "Oca",
                "Şub",
                "Mar",
                "Nis",
                "May",
                "Haz",
                "Tem",
                "Ağu",
                "Eyl",
                "Eki",
                "Kas",
                "Ara",
              ];
              return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
            })
          );
          break;
        default:
          break;
      }
    } else if (type === "gider") {
      switch (category) {
        case "Countries":
          setSelectedGiderCountries([
            ...new Set(allGider.map((item) => item.bolge)),
          ]);
          break;
        case "Years":
          setSelectedGiderYears([...new Set(allGider.map((item) => item.yil))]);
          break;
        case "Months":
          setSelectedGiderMonths(
            [
              ...new Set(allGider.map((item) => monthNumberToName(item.ay))),
            ].sort((a, b) => {
              const monthsOrder = [
                "Oca",
                "Şub",
                "Mar",
                "Nis",
                "May",
                "Haz",
                "Tem",
                "Ağu",
                "Eyl",
                "Eki",
                "Kas",
                "Ara",
              ];
              return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
            })
          );
          break;
        default:
          break;
      }
    }
  };

  const calculateCurrentRateInTL = (values) => {
    let totalNetInTL = 0;
    for (let doviz in values) {
      const rate = rates[doviz] || 1;
      totalNetInTL += values[doviz].net * rate;
    }
    return totalNetInTL;
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Container fluid>
            <Row className="mb-3 filter-section">
              <Col xs={4}>
                <Row>
                  <Col xs={12}>
                    <div className="filter-header">
                      <h3>Gelir Yılları</h3>
                      <div className="filter-icons">
                        <FilterListIcon
                          onClick={() => selectAll("Years", "gelir")}
                          className="icon-button"
                        />
                        <ClearIcon
                          onClick={() => clearSelection("Years", "gelir")}
                          className="icon-button"
                        />
                      </div>
                    </div>
                    <div className="button-container scrollable">
                      {availableGelirYears.map((year) => (
                        <Button
                          key={year}
                          variant="contained"
                          color={
                            selectedGelirYears.includes(year)
                              ? "primary"
                              : "default"
                          }
                          onClick={() => toggleGelirSelection("Years", year)}
                          className={
                            selectedGelirYears.includes(year)
                              ? "MuiButton-root selected small-button"
                              : "MuiButton-root small-button"
                          }
                        >
                          {year}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="filter-header">
                      <h3>Gelir Ayları</h3>
                      <div className="filter-icons">
                        <FilterListIcon
                          onClick={() => selectAll("Months", "gelir")}
                          className="icon-button"
                        />
                        <ClearIcon
                          onClick={() => clearSelection("Months", "gelir")}
                          className="icon-button"
                        />
                      </div>
                    </div>
                    <div className="button-container">
                      {availableGelirMonths.map((month) => (
                        <Button
                          key={month}
                          variant="contained"
                          color={
                            selectedGelirMonths.includes(month)
                              ? "primary"
                              : "default"
                          }
                          onClick={() => toggleGelirSelection("Months", month)}
                          className={
                            selectedGelirMonths.includes(month)
                              ? "MuiButton-root selected small-button"
                              : "MuiButton-root small-button"
                          }
                        >
                          {month}
                        </Button>
                      ))}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="filter-header">
                      <h3>Gelir Ülkeleri</h3>
                      <div className="filter-icons">
                        <FilterListIcon
                          onClick={() => selectAll("Countries", "gelir")}
                          className="icon-button"
                        />
                        <ClearIcon
                          onClick={() => clearSelection("Countries", "gelir")}
                          className="icon-button"
                        />
                      </div>
                    </div>
                    <div className="button-container">
                      {availableGelirCountries.map((country) => (
                        <Button
                          key={country}
                          variant="contained"
                          color={
                            selectedGelirCountries.includes(country)
                              ? "primary"
                              : "default"
                          }
                          onClick={() =>
                            toggleGelirSelection("Countries", country)
                          }
                          className={
                            selectedGelirCountries.includes(country)
                              ? "MuiButton-root selected small-button"
                              : "MuiButton-root small-button"
                          }
                        >
                          {country}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={4}></Col> {/* Boşluk için */}
              <Col xs={4}>
                <Row>
                  <Col xs={12}>
                    <div className="filter-header">
                      <h3>Gider Yılları</h3>
                      <div className="filter-icons">
                        <FilterListIcon
                          onClick={() => selectAll("Years", "gider")}
                          className="icon-button"
                        />
                        <ClearIcon
                          onClick={() => clearSelection("Years", "gider")}
                          className="icon-button"
                        />
                      </div>
                    </div>
                    <div className="button-container scrollable">
                      {availableGiderYears.map((year) => (
                        <Button
                          key={year}
                          variant="contained"
                          color={
                            selectedGiderYears.includes(year)
                              ? "secondary"
                              : "default"
                          }
                          onClick={() => toggleGiderSelection("Years", year)}
                          className={
                            selectedGiderYears.includes(year)
                              ? "MuiButton-root selected small-button"
                              : "MuiButton-root small-button"
                          }
                        >
                          {year}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="filter-header">
                      <h3>Gider Ayları</h3>
                      <div className="filter-icons">
                        <FilterListIcon
                          onClick={() => selectAll("Months", "gider")}
                          className="icon-button"
                        />
                        <ClearIcon
                          onClick={() => clearSelection("Months", "gider")}
                          className="icon-button"
                        />
                      </div>
                    </div>
                    <div className="button-container">
                      {availableGiderMonths.map((month) => (
                        <Button
                          key={month}
                          variant="contained"
                          color={
                            selectedGiderMonths.includes(month)
                              ? "secondary"
                              : "default"
                          }
                          onClick={() => toggleGiderSelection("Months", month)}
                          className={
                            selectedGiderMonths.includes(month)
                              ? "MuiButton-root selected small-button"
                              : "MuiButton-root small-button"
                          }
                        >
                          {month}
                        </Button>
                      ))}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="filter-header">
                      <h3>Gider Ülkeleri</h3>
                      <div className="filter-icons">
                        <FilterListIcon
                          onClick={() => selectAll("Countries", "gider")}
                          className="icon-button"
                        />
                        <ClearIcon
                          onClick={() => clearSelection("Countries", "gider")}
                          className="icon-button"
                        />
                      </div>
                    </div>
                    <div className="button-container">
                      {availableGiderCountries.map((country) => (
                        <Button
                          key={country}
                          variant="contained"
                          color={
                            selectedGiderCountries.includes(country)
                              ? "secondary"
                              : "default"
                          }
                          onClick={() =>
                            toggleGiderSelection("Countries", country)
                          }
                          className={
                            selectedGiderCountries.includes(country)
                              ? "MuiButton-root selected small-button"
                              : "MuiButton-root small-button"
                          }
                        >
                          {country}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <h1 className="total-row">
              <div className="total-col total-gelir">
                Toplam Gelir:{" "}
                {totalGelir.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="total-col net-tutar">
                <span>
                  Net Tutar:{" "}
                  {netTutar.toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  ₺
                </span>
              </div>
              <div className="total-col total-gider">
                Toplam Gider:{" "}
                {totalGider.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
            </h1>

            <Row>
              {Object.entries(topDovizValues).map(([doviz, values], index) => (
                <Col key={index} className="card-container">
                  <Card>
                    <Card.Body className="card-body">
                      <Card.Title className="card-title">{doviz}</Card.Title>
                      <Card.Text className="card-text">
                        Gelir:{" "}
                        {values.gelir.toLocaleString("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        {doviz}
                      </Card.Text>
                      <Card.Text className="card-text">
                        Gider:{" "}
                        {values.gider.toLocaleString("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        {doviz}
                      </Card.Text>
                      <Card.Text className="card-text">
                        Net:{" "}
                        {values.net.toLocaleString("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        {doviz}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            <h1 className="updated-rate">
              Güncel Kurdaki karşılığı:{" "}
              {calculateCurrentRateInTL(topDovizValues).toLocaleString(
                "tr-TR",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              ₺
            </h1>
          </Container>
        )}
      </Layout>
    </ThemeProvider>
  );
};

export default Genel;
