import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, message, Modal, Form, Input } from "antd";
import Layout from "../Layout/Layout";

const GiderListesi = () => {
  const [giderler, setGiderler] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingGider, setEditingGider] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const fetchGiderler = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/editor/get-user-expenses");
      setGiderler(response.data);
    } catch (error) {
      message.error("Gider verileri alınırken hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGiderler();
  }, []);

  const handleEdit = (gider) => {
    setEditingGider(gider);
    setIsModalVisible(true);
  };

  const handleDelete = (id) => {
    setDeletingId(id);
    setIsDeleteConfirmVisible(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`/editor/delete-user-expense/${deletingId}`);
      message.success("Gider başarıyla silindi");
      fetchGiderler();
    } catch (error) {
      message.error("Gider silinirken hata oluştu");
    } finally {
      setIsDeleteConfirmVisible(false);
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteConfirmVisible(false);
    setDeletingId(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingGider(null);
  };

  const handleSave = async (values) => {
    setConfirmVisible(true);
  };

  const handleConfirmSave = async () => {
    try {
      await axios.put(`/editor/edit-user-expense/${editingGider._id}`, editingGider);
      message.success("Gider başarıyla güncellendi");
      fetchGiderler();
      handleCancel();
    } catch (error) {
      message.error("Gider güncellenirken hata oluştu");
    } finally {
      setConfirmVisible(false);
    }
  };

  const handleConfirmCancel = () => {
    setConfirmVisible(false);
  };

  const columns = [
    { title: "Tarih", dataIndex: "tarih", key: "tarih" },
    { title: "Ana Gider Türü", dataIndex: "ana_gider_turu", key: "ana_gider_turu" },
    { title: "Alt Gider Türü", dataIndex: "alt_gider_turu", key: "alt_gider_turu" },
    { title: "Alt Gider Kalemi", dataIndex: "alt_gider_kalemi", key: "alt_gider_kalemi" },
    { title: "Bölge", dataIndex: "bolge", key: "bolge" },
    { title: "Değer", dataIndex: "deger", key: "deger" },
    { title: "Döviz", dataIndex: "doviz", key: "doviz" },
    { title: "Tutar", dataIndex: "tutar", key: "tutar" },
    { title: "Tür", dataIndex: "tur", key: "tur" },
    { title: "Partner", dataIndex: "partner", key: "partner" },
    { title: "Açıklama", dataIndex: "aciklama", key: "aciklama" },
    {
      title: "İşlem",
      key: "action",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>
            Düzenle
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record._id)}>
            Sil
          </Button>
        </span>
      ),
    },
  ];

  return (
    <Layout>
      <div>
        <h2>Gider Listesi</h2>
        <Table
          columns={columns}
          dataSource={giderler}
          rowKey={(record) => record._id}
          loading={loading}
        />
        <Modal
          title="Gider Düzenle"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            initialValues={editingGider}
            onFinish={handleSave}
            layout="vertical"
          >
            <Form.Item name="tarih" label="Tarih">
              <Input />
            </Form.Item>
            <Form.Item name="ana_gider_turu" label="Ana Gider Türü">
              <Input />
            </Form.Item>
            <Form.Item name="alt_gider_turu" label="Alt Gider Türü">
              <Input />
            </Form.Item>
            <Form.Item name="alt_gider_kalemi" label="Alt Gider Kalemi">
              <Input />
            </Form.Item>
            <Form.Item name="bolge" label="Bölge">
              <Input />
            </Form.Item>
            <Form.Item name="deger" label="Değer">
              <Input />
            </Form.Item>
            <Form.Item name="doviz" label="Döviz">
              <Input />
            </Form.Item>
            <Form.Item name="tutar" label="Tutar">
              <Input />
            </Form.Item>
            <Form.Item name="tur" label="Tür">
              <Input />
            </Form.Item>
            <Form.Item name="partner" label="Partner">
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label="Açıklama">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Onay"
          visible={confirmVisible}
          onOk={handleConfirmSave}
          onCancel={handleConfirmCancel}
          okText="Evet"
          cancelText="Hayır"
        >
          <p>Bu değişiklikleri kaydetmek istediğinize emin misiniz?</p>
        </Modal>
        <Modal
          title="Silme Onayı"
          visible={isDeleteConfirmVisible}
          onOk={confirmDelete}
          onCancel={handleCancelDelete}
          okText="Evet"
          cancelText="Hayır"
        >
          <p>Bu veriyi silmek istediğinize emin misiniz?</p>
        </Modal>
      </div>
    </Layout>
  );
};

export default GiderListesi;
