import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import "./CommonModal.css";

const CommonModal = forwardRef(
  (
    {
      showModal,
      handleClose,
      formData,
      handleChange,
      handleSubmit,
      getCurrentDate,
      statusMessage,
      loading,
      formFields,
      showConfirmation,
      handleConfirmationClose,
      confirmSubmit,
      giderTurleri,
      altGT,
      altGK,
      bolgeler,
    },
    ref
  ) => {
    const [newValues, setNewValues] = useState({
      ana_gider_turu: "",
      alt_gider_turu: "",
      alt_gider_kalemi: "",
      bolge: "",
    });

    const [showNewInput, setShowNewInput] = useState({
      ana_gider_turu: false,
      alt_gider_turu: false,
      alt_gider_kalemi: false,
      bolge: false,
    });

    const handleSelectChange = (selectedOption, field) => {
      if (selectedOption.value === "addNew") {
        setShowNewInput({ ...showNewInput, [field]: true });
      } else {
        handleChange({ target: { name: field, value: selectedOption.value } });
        setShowNewInput({ ...showNewInput, [field]: false });
      }
    };

    const handleNewValueChange = (e, field) => {
      setNewValues({ ...newValues, [field]: e.target.value });
    };

    const handleNewValueSave = (field) => {
      handleChange({ target: { name: field, value: newValues[field] } });
      setShowNewInput({ ...showNewInput, [field]: false });
    };

    useImperativeHandle(ref, () => ({
      resetForm() {
        document.getElementById("commonForm").reset();
      },
    }));

    const createSelectOptions = (options) =>
      options.map((option) => ({ value: option, label: option }));

    return (
      <>
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          className="modal-fade-in"
        >
          <Modal.Header closeButton>
            <Modal.Title>Veri Girişi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id="commonForm" onSubmit={handleSubmit}>
              {formFields.map((field, index) => (
                <div className="form-group" key={index}>
                  <Form.Group controlId={`form${field.name}`}>
                    <Form.Label>{field.label}</Form.Label>
                    {field.name === "ana_gider_turu" ||
                    field.name === "alt_gider_turu" ||
                    field.name === "alt_gider_kalemi" ||
                    field.name === "bolge" ? (
                      <div className="select-wrapper">
                        <Select
                          name={field.name}
                          value={{
                            value: formData[field.name],
                            label: formData[field.name],
                          }}
                          onChange={(selectedOption) =>
                            handleSelectChange(selectedOption, field.name)
                          }
                          options={[
                            { value: "addNew", label: "Yeni Ekle..." },
                            ...createSelectOptions(
                              field.name === "ana_gider_turu"
                                ? giderTurleri
                                : field.name === "Alt Gider Türleri"
                                ? altGK
                                : field.name === "Alt Gider Kalemi"
                                ? altGT
                                : bolgeler
                            ),
                          ]}
                        />
                        {console.log(`Options for ${field.name}:`, 
                          field.name === "ana_gider_turu"
                            ? giderTurleri
                            : field.name === "Alt Gider Türleri"
                            ? altGK
                            : field.name === "Alt Gider Kalemi"
                            ? altGT
                            : bolgeler
                        )}
                        {showNewInput[field.name] && (
                          <div className="new-option-input">
                            <Form.Control
                              type="text"
                              value={newValues[field.name]}
                              onChange={(e) =>
                                handleNewValueChange(e, field.name)
                              }
                              placeholder={`Yeni ${field.label} girin`}
                              className="mb-2"
                            />
                            <Button
                              variant="success"
                              className="new-option-button"
                              onClick={() => handleNewValueSave(field.name)}
                            >
                              Kaydet
                            </Button>
                          </div>
                        )}
                      </div>
                    ) : field.type === "select" ? (
                      <Form.Control
                        as="select"
                        name={field.name}
                        value={formData[field.name]}
                        required={field.required}
                        onChange={handleChange}
                      >
                        {field.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Control>
                    ) : field.type === "textarea" ? (
                      <Form.Control
                        as="textarea"
                        name={field.name}
                        value={formData[field.name]}
                        required={field.required}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        rows={3}
                      />
                    ) : (
                      <Form.Control
                        type={field.type}
                        name={field.name}
                        value={formData[field.name]}
                        required={field.required}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                        max={
                          field.type === "date" ? getCurrentDate() : undefined
                        }
                        readOnly={field.readOnly}
                      />
                    )}
                  </Form.Group>
                </div>
              ))}
              <div className="button-container">
                <Button variant="primary" type="submit" className="w-100">
                  {loading ? "Kaydediliyor..." : "Kaydet"}
                </Button>
              </div>
            </Form>
            {statusMessage && <p className="status-message">{statusMessage}</p>}
          </Modal.Body>
        </Modal>

        <Modal
          show={showConfirmation}
          onHide={handleConfirmationClose}
          centered
          className="modal-fade-in"
        >
          <Modal.Header closeButton>
            <Modal.Title>Onayla</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Bu veriyi eklemek istediğinizden emin misiniz?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleConfirmationClose}>
              Hayır
            </Button>
            <Button variant="primary" onClick={confirmSubmit}>
              Evet
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
);

export default CommonModal;
