import React, { useState, useEffect, useContext } from "react";
import { message, Spin, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaUser, FaLock, FaEnvelope } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const CorporateLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState("");
    const [msg, setMsg] = useState("");
    const { loginCorp, user } = useContext(AuthContext);
    const navigate = useNavigate();


    const handleSubmit = async (event, isLogin) => {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const values = Object.fromEntries(formData.entries());
    
        setMsg("");
    
        try {
          setLoading(true);
          if (isLogin) {
            await loginCorp(values.email, values.password);
            navigate("/");
          } else {
            if (values.password !== values.confirmPassword) {
              setMsg("Passwords do not match");
              setLoading(false);
              return;
            }
    
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(values.email)) {
              setMsg("Please enter a valid email address");
              setLoading(false);
              return;
            }
    
           

             navigate("/"); // Kayıttan sonra login sayfasına yönlendirme
          }
        } catch (error) {
          message.error("Eposta yada şifre hatalı");
          setMsg(error.response?.data?.message || "Error registering user");
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        if (localStorage.getItem("user")) {
          navigate("/");
        }
      }, [navigate]);
    

    return (
        <div className="login-page">
          <div className={`wrapper${action}`}>
            <div className="form-box login">
              <form onSubmit={(event) => handleSubmit(event, true)}>
                <h1>Login</h1>
                {msg && (
                  <Alert
                    message={msg}
                    type="error"
                    showIcon
                    style={{ marginBottom: "20px" }}
                  />
                )}
                <div className="input-box">
                  <input type="email" name="email" placeholder="Email" required />
                  <FaUser className="icon" />
                </div>
                <div className="input-box">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                  />
                  <FaLock className="icon" />
                </div>
                <div className="remember-forgot">
                  <label>
                    <input type="checkbox" />
                    Remember Me
                  </label>
                  <Link to="/forgotPassword">Forgot Password?</Link>{" "}
                  {/* Forgot Password bağlantısı */}
                </div>
                <button type="submit" disabled={loading}>
                  Login
                </button>
                {loading && <Spin style={{ marginTop: "20px" }} />}
                
              </form>
            </div>
            <div className="form-box register">
              <form onSubmit={(event) => handleSubmit(event, false)}>
                <h1>Register</h1>
                {msg && (
                  <Alert
                    message={msg}
                    type="error"
                    showIcon
                    style={{ marginBottom: "20px" }}
                  />
                )}
                <div className="input-box">
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    required
                  />
                  <FaUser className="icon" />
                </div>
                <div className="input-box">
                  <input type="email" name="email" placeholder="Email" required />
                  <FaEnvelope className="icon" />
                </div>
                <div className="input-box">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                  />
                  <FaLock className="icon" />
                </div>
                <div className="input-box">
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    required
                  />
                  <FaLock className="icon" />
                </div>
                <div className="remember-forgot">
                  <label>
                    <input type="checkbox" />I agree to the terms & conditions
                  </label>
                </div>
                
                {loading && <Spin style={{ marginTop: "20px" }} />}
                
              </form>
            </div>
          </div>
        </div>
      );
    
};

export default CorporateLogin;
