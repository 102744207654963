import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../Layout/Layout";;

const CorporateManagement = () => {
  const [corporates, setCorporates] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    const fetchCorporates = async () => {
      try {
        const res = await axios.get('/corporate/get-corporates', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCorporates(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCorporates();
  }, []);

  const addCorporate = async () => {
    try {
      const res = await axios.post('/corporate/add-corporate', { name }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCorporates([...corporates, res.data]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
    <div>
      <h1>Kurum Yönetimi</h1>
      <input
        type="text"
        placeholder="Kurum Adı"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <button onClick={addCorporate}>Kurum Ekle</button>
      <ul>
        {corporates.map(corporate => (
          <li key={corporate._id}>{corporate.name}</li> // Benzersiz key ekleyin
        ))}
      </ul>
    </div>
    </Layout>
  );
};

export default CorporateManagement;
