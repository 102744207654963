import React, { createContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { message } from "antd";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false); // Used to indicate loading state
  const timeoutId = useRef(null);

  useEffect(() => {
    const fetchUser = async (token) => {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await axios.get("/auth/user");
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    const fetchCorporateUser = async (token) => {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const userResponse = await axios.get("/corporate/get-corporate-users");
        setUser(userResponse.data.user);
      } catch (error) {
        console.error("Error fetching corporate users:", error);
        // Optional: logout();
      }
    };

    const token = localStorage.getItem("token");
    const token2 = localStorage.getItem("tokenCorp");

    if (token) {
      fetchUser(token);
    } else if (token2) {
      fetchCorporateUser(token2);
    }
  }, []);

  const resetTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      logout("auto"); // Call logout for automatic session timeout
    }, 600000); // 10 minutes (600000 ms)
  };

  const login = async (email, password) => {
    try {
      setLoading(true); // Start loading before the async operation
      const response = await axios.post("/auth/login", { email, password });
      localStorage.setItem("token", response.data.token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.token}`;
      const userResponse = await axios.get("/auth/user");
      setUser(userResponse.data.user); // Update user data
      message.success("Başarıyla giriş yapıldı"); // Success message
      resetTimeout();
    } catch (error) {
      message.error(error.response?.data?.message || "Giriş Başarısız");
    } finally {
      setLoading(false); // End loading regardless of success or failure
    }
  };

  const loginCorp = async (email, password) => {
    try {
      setLoading(true); // Start loading before the async operation
      const response = await axios.post("/corporate/login-corporate-user", {
        email,
        password,
      });
      localStorage.setItem("tokenCorp", response.data.token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.token}`;
      message.success("Başarıyla giriş yapıldı"); // Success message
      resetTimeout(); // Reset session timeout
      window.location.reload();
    } catch (error) {
      message.error(error.response?.data?.message || "Giriş Başarısız");
    } finally {
      setLoading(false); // End loading regardless of success or failure
    }
  };

  const logout = (type) => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenCorp");
    setUser(null);
    delete axios.defaults.headers.common["Authorization"];
    if (type !== "auto") {
      message.success("Başarıyla çıkış yapıldı");
    } else {
      message.info("Otomatik olarak çıkış yapıldı");
    }
  };

  useEffect(() => {
    const handleActivity = () => {
      resetTimeout();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, loginCorp }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
