import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Select } from "antd";
import { message as antdMessage } from "antd";
import Layout from "../Layout/Layout";
import axios from "axios";
import Spinners from "../Layout/Spinners";
import { v4 as uuidv4 } from "uuid";
import GraphComponent from "../components/GraphComponents/GraphComponents";
import Parapara from "../components/Parapara/Parapara";
import CommonModal from "../components/CommonModal/CommonModalGider";
import { FaPlus } from "react-icons/fa";
import {
  Form,
  Container,
  Row,
  Col,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import "./HomePage.css";
import CircularProgressBar from "../components/CircularProgressBar/CircularProgressBar";

const { Option } = Select;

const Gider = () => {
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalFilteredExpenditures, setTotalFilteredExpenditures] = useState(0);
  const [monthlyExpenditures, setMonthlyExpenditures] = useState([]);
  const [totalExpenseByCountry, setTotalExpenseByCountry] = useState({});
  const [totalExpenseByProje, setTotalExpenseByProje] = useState({});
  const [progress, setProgress] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [exchangeRates, setExchangeRates] = useState({});
  const [totalTutarFilteredExpenditures, setTutarTotalFilteredExpenditures] =
    useState(0);
  const [tutar, setTutar] = useState(0);
  const [showModal, setShowModal] = useState(false); // Modal state

  const [loading, setLoading] = useState(false);
  const [allGider, setAllGider] = useState([]);
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [giderTurleri, setGiderTurleri] = useState([]);
  const [altGT, setAltGT] = useState([]);
  const [altGK, setAGK] = useState([]);
  const [bolgeler, setBolgeler] = useState([]);

  const [formData, setFormData] = useState({
    giderTarihi: "",
    ana_gider_turu: "",
    alt_gider_turu: "",
    alt_gider_kalemi: "",
    bolge: "",
    deger: "",
    doviz: "",
    tur: "",
    partner: "",
    aciklama: "",
    tutar: "",
  });

  const [rates, setRates] = useState({});

  const modalRef = useRef(); // Modal ref

  // Onay penceresi için state ve fonksiyonlar
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleConfirmationClose = () => setShowConfirmation(false);
  const handleConfirmationShow = () => setShowConfirmation(true);

  const turkishMonths = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  // Tüm işlemleri getir
  const getAllGiders = async () => {
    try {
      setLoading(true);
      const res1 = await axios.post("/giders/get-gider");

      const data = Array.isArray(res1.data) ? res1.data : [];
      console.log("Fetched Giders:", data); // Geri dönen verileri kontrol edin

      setLoading(false);
      setAllGider(data);

      if (data.length === 0) {
        antdMessage.info("Herhangi bir gider kaydı bulunmuyor.");
        setTotalIncome(0);
        setTutar(0);
        return;
      }

      calculateMonthlyExpenditures(data);

      const totalIncome = data.reduce(
        (sum, item) => sum + parseFloat(item.tutar.replace(".", ",")),
        0
      );

      const totalTutar = data.reduce(
        (sum, item) => sum + parseFloat(item.tutar.replace(".", ",")),
        0
      );

      setTotalIncome(totalIncome);
      setTutar(totalTutar);
    } catch (error) {
      console.error("Error fetching data:", error);
      antdMessage.error("Veri alınırken bir hata oluştu");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.tur) {
      antdMessage.error("Lütfen tür alanını doldurun");
      return;
    }
    handleConfirmationShow();
  };

  const confirmSubmit = async () => {
    const values = {
      tarih: new Date(formData.giderTarihi),
      ana_gider_turu: formData.ana_gider_turu,
      alt_gider_turu: formData.alt_gider_turu,
      alt_gider_kalemi: formData.alt_gider_kalemi,
      bolge: formData.bolge,
      deger: formData.deger,
      tutar: formData.tutar,
      doviz: formData.doviz,
      tur: formData.tur,
      partner: formData.partner,
      aciklama: formData.aciklama,
    };

    setLoading(true);
    try {
      await axios.post("/giders/add-gider", values);
      setMessage("Veri Eklendi");
      setTimeout(() => {
        setStatusMessage("");
        window.location.reload(); // Sayfanın yenilenmesi için
      }, 1000);
      setFormData({
        giderTarihi: "",
        ana_gider_turu: "",
        alt_gider_turu: "",
        alt_gider_kalemi: "",
        bolge: "",
        deger: "",
        doviz: "",
        tur: "",
        partner: "",
        aciklama: "",
        tutar: "",
      });
      handleConfirmationClose();
      handleClose();
    } catch (error) {
      console.error("Error adding income:", error.response || error.message);
      setMessage(
        "Error adding income: " +
          (error.response?.data?.message || error.message)
      );
      setTimeout(() => {
        setStatusMessage("");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const giderTurleriRes = await axios.get("/giders/gider-turleri");
        console.log("Gider Türleri:", giderTurleriRes.data); // Konsola log ekleyin
        setGiderTurleri(giderTurleriRes.data);

        const altGTRes = await axios.get("/giders/alt-gider-turu");
        console.log("Alt Gider Türü:", altGTRes.data); // Konsola log ekleyin
        setAltGT(altGTRes.data);

        const agkRes = await axios.get("/giders/alt-gider-kalemi");
        console.log("Alt Gider Kalemi:", agkRes.data); // Konsola log ekleyin
        setAGK(agkRes.data);

        const bolgelerRes = await axios.get("/giders/bolgeler");
        console.log("Bölgeler:", bolgelerRes.data); // Konsola log ekleyin
        setBolgeler(bolgelerRes.data);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, []);

  useEffect(() => {
    // Döviz kurlarını API'den çek
    fetch("https://api.exchangerate-api.com/v4/latest/USD")
      .then((response) => response.json())
      .then((data) => {
        // USD ve EUR'nin TL cinsinden karşılıklarını hesapla
        const usdToTry = data.rates.TRY;
        const eurToTry = usdToTry / data.rates.EUR;
        const gbpToTry = usdToTry / data.rates.GBP;

        // Diğer döviz kurlarını TL cinsinden güncelle
        setRates({
          USD: usdToTry,
          EUR: eurToTry,
          GBP: gbpToTry,
          TRY: 1, // TL'nin kendi değeri 1'dir
        });
        // Güncellenmiş exchangeRates'i konsola yazdır
        console.log("Güncellenmiş 1:", {
          USD: usdToTry,
          EUR: eurToTry,
          GBP: gbpToTry,
          TRY: 1,
        });
      })
      .catch((error) => {
        console.error("Error fetching exchange rates:", error);
      });
  }, []);

  useEffect(() => {
    console.log("Güncellenmiş 2:", rates);
  }, [rates]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "addNew") {
      const newOption = prompt("Yeni bir seçenek ekleyin:");
      if (newOption) {
        if (name === "ana_gider_turu") {
          setGiderTurleri([...giderTurleri, newOption]);
          setFormData({ ...formData, [name]: newOption });
        } else if (name === "alt_gider_turu") {
          setAltGT([...altGT, newOption]);
          setFormData({ ...formData, [name]: newOption });
        } else if (name === "alt_gider_kalemi") {
          setAGK([...altGK, newOption]);
          setFormData({ ...formData, [name]: newOption });
        } else if (name === "bolge") {
          setBolgeler([...bolgeler, newOption]);
          setFormData({ ...formData, [name]: newOption });
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (formData.deger && formData.doviz) {
      const deger = parseFloat(formData.deger);
      const kur = rates[formData.doviz];
      if (deger && kur) {
        const tutar = deger * kur;
        setFormData((prevData) => ({
          ...prevData,
          tutar: tutar.toFixed(2),
        }));
      }
    }
  }, [formData.deger, formData.doviz, rates]);

  useEffect(() => {
    if (formData.deger && formData.doviz) {
      const deger = parseFloat(formData.deger);
      const kur = rates[formData.doviz];
      if (deger && kur) {
        const tutar = deger * kur;
        setFormData((prevData) => ({
          ...prevData,
          tutar: tutar.toFixed(2),
        }));
      }
    }
  }, [formData.deger, formData.doviz, rates]);
  useEffect(() => {
    getAllGiders();
  }, []);

  useEffect(() => {
    updateFilters();
  }, [
    selectedYears,
    selectedMonths,
    selectedCountries,
    selectedProjects,
    allGider,
  ]);

  useEffect(() => {
    const monthlyExpendituresData = calculateMonthlyExpenditures(filteredData);
    setMonthlyExpenditures(monthlyExpendituresData);
  }, [filteredData]);

  useEffect(() => {
    if (totalTutarFilteredExpenditures > 0) {
      const progressValue = (
        (totalTutarFilteredExpenditures / tutar) *
        100
      ).toFixed(2);
      setProgress(progressValue);
    }
  }, [tutar, totalTutarFilteredExpenditures]);

  // Aylara göre toplam giderleri hesapla ve konsola yazdır
  const calculateMonthlyExpenditures = (data) => {
    const monthlyExpendituresData = {};
    data.forEach((item) => {
      const month = item.ay;
      const amount = parseFloat(item.tutar.replace(".", ","));
      if (!monthlyExpendituresData[month]) {
        monthlyExpendituresData[month] = 0;
      }
      monthlyExpendituresData[month] += amount;
    });

    return monthlyExpendituresData;
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Dövizlere göre toplam giderleri hesapla ve konsola yazdır
  const calculateTotalExpenseByExchange = (data) => {
    const exchangeRates = {};

    data.forEach((item) => {
      const doviz = item.doviz;
      const income = parseFloat(item.tutar.replace(".", ","));
      if (!exchangeRates[doviz]) {
        exchangeRates[doviz] = 0;
      }
      exchangeRates[doviz] += income;
    });

    setExchangeRates(exchangeRates);
  };

  // Ülkelere göre toplam giderleri hesapla ve konsola yazdır
  const calculateTotalExpenseByCountryAndDisplay = (data) => {
    const totalExpenseByCountry = {};

    data.forEach((item) => {
      const country = item.bolge;
      const income = parseFloat(item.tutar.replace(".", ","));
      if (!totalExpenseByCountry[country]) {
        totalExpenseByCountry[country] = 0;
      }
      totalExpenseByCountry[country] += income;
    });
    setTotalExpenseByCountry(totalExpenseByCountry);
  };

  const calculateTotalExpenseByProjeAndDisplay = (data) => {
    const totalExpenseByProje = {};

    data.forEach((item) => {
      const alt_gider_turu = item.alt_gider_turu;
      const alt_gider_kalemi = item.alt_gider_kalemi;
      const faincome = parseFloat(item.tutar.replace(".", ","));

      if (!totalExpenseByProje[alt_gider_turu]) {
        totalExpenseByProje[alt_gider_turu] = {};
      }
      if (!totalExpenseByProje[alt_gider_turu][alt_gider_kalemi]) {
        totalExpenseByProje[alt_gider_turu][alt_gider_kalemi] = 0;
      }
      totalExpenseByProje[alt_gider_turu][alt_gider_kalemi] += faincome;
    });

    // Konsola bastır
    console.log(
      "Alt Gider Türü ve Kalemi Göre Toplam Giderler:",
      totalExpenseByProje
    );
    setTotalExpenseByProje(totalExpenseByProje);
  };

  // Filtreleri güncelle
  const updateFilters = () => {
    const filteredData = allGider.filter(
      (item) =>
        (selectedYears.length === 0 || selectedYears.includes(item.yil)) &&
        (selectedMonths.length === 0 || selectedMonths.includes(item.ay)) &&
        (selectedCountries.length === 0 ||
          selectedCountries.includes(item.bolge)) &&
        (selectedProjects.length === 0 || selectedProjects.includes(item.proje))
    );

    setFilteredData(filteredData);

    const totalExpenditures = filteredData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(".", ",")),
      0
    );
    const totaltutarExpenditures = filteredData.reduce(
      (sum, item) => sum + parseFloat(item.tutar.replace(".", ",")),
      0
    );
    setTutarTotalFilteredExpenditures(totaltutarExpenditures);
    setTotalFilteredExpenditures(totalExpenditures);

    const years = [
      ...new Set(
        allGider
          .filter(
            (item) =>
              (selectedMonths.length === 0 ||
                selectedMonths.includes(item.ay)) &&
              (selectedCountries.length === 0 ||
                selectedCountries.includes(item.bolge))
          )
          .map((item) => item.yil)
      ),
    ].sort((a, b) => a - b);
    const months = [
      ...new Set(
        allGider
          .filter(
            (item) =>
              (selectedYears.length === 0 ||
                selectedYears.includes(item.yil)) &&
              (selectedCountries.length === 0 ||
                selectedCountries.includes(item.bolge))
          )
          .map((item) => item.ay)
      ),
    ].sort((a, b) => a - b);
    const countries = [
      ...new Set(
        allGider
          .filter(
            (item) =>
              (selectedYears.length === 0 ||
                selectedYears.includes(item.yil)) &&
              (selectedMonths.length === 0 || selectedMonths.includes(item.ay))
          )
          .map((item) => item.bolge)
      ),
    ];

    setAvailableYears(years);
    setAvailableMonths(months);
    setAvailableCountries(countries);
    calculateTotalExpenseByCountryAndDisplay(filteredData);
    calculateTotalExpenseByProjeAndDisplay(filteredData);
    calculateTotalExpenseByExchange(filteredData);

    setLoading(false);
  };

  const formFields = [
    {
      name: "giderTarihi",
      label: "Gider Tarihi",
      type: "date",
      required: true,
    },
    {
      name: "ana_gider_turu",
      label: "Ana gider Türü",
      type: "text",
      required: true,
    },
    {
      name: "alt_gider_turu",
      label: "Alt Gider Türü",
      type: "text",
      required: true,
      placeholder: "Alt Gider Türü giriniz",
    },
    {
      name: "alt_gider_kalemi",
      label: "Alt Gider Kalemi",
      type: "text",
      required: true,
      placeholder: "Alt Gider Kalemi giriniz",
    },
    {
      name: "bolge",
      label: "Bölge",
      type: "text",
      required: true,
      placeholder: "Bölge ismi giriniz",
    },
    {
      name: "deger",
      label: "Değer",
      type: "number",
      required: true,
      placeholder: "Değer miktarı girin",
    },
    {
      name: "doviz",
      label: "Döviz",
      type: "select",
      required: true,
      options: [
        { value: "", label: "Seçiniz" }, // Bu varsayılan değer olmamalı, kullanıcı bir değer seçmeli
        { value: "TRY", label: "TL" },
        { value: "EUR", label: "EUR" },
        { value: "USD", label: "USD" },
        { value: "GBP", label: "GBP" },
      ],
    },
    {
      name: "tutar",
      label: "Tutar",
      type: "text",
      readOnly: true,
      placeholder: "Tutar otomatik hesaplanacak",
    },

    {
      name: "tur",
      label: "Tür",
      type: "select",
      required: true,
      options: [
        { value: "Seçiniz", label: "Seçiniz" },
        { value: "Nakit", label: "Nakit" },
        { value: "Kredi", label: "Kredi" },
        { value: "Banka", label: "Banka" },
      ],
    },
    {
      name: "partner",
      label: "Partner",
      type: "text",
      placeholder: "Partner girin",
    },
    {
      name: "aciklama",
      label: "Açıklama",
      type: "textarea",
      required: true,
      placeholder: "Açıklama girin",
    },
  ];

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChangeGider = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadGider = async () => {
    if (!selectedFile) {
      antdMessage.error("Lütfen bir dosya seçin");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post("/import/import-gider", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        antdMessage.success("Dosya başarıyla yüklendi");
      }
    } catch (error) {
      antdMessage.error("Dosya yüklenirken hata oluştu");
      console.error("Upload error:", error);
    }
  };

  const handleExportGider = async () => {
    try {
      const response = await axios.get("/exports/export-gider", {
        responseType: "blob", // Bu önemli, dosyayı blob olarak almak için
      });

      // Blob'dan bir URL oluşturun
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "giderler.xlsx"); // Dosya adı
      document.body.appendChild(link);
      link.click();

      antdMessage.success("Giderler başarıyla dışa aktarıldı");
    } catch (error) {
      console.error("Giderleri dışa aktarma hatası:", error);
      antdMessage.error("Giderleri dışa aktarırken bir hata oluştu");
    }
  };
  const handleDownload = async () => {
    try {
      const response = await axios({
        url: '/download/gider-template',
        method: 'GET',
        responseType: 'blob', // Blob olarak yanıt alın
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'gider_template.xlsx'); // Dosya adı
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Dosya indirilirken hata:', error);
    }
  };

  const handleRedirect = () => {
    window.open('https://www.youtube.com/watch?v=YourVideoIDD', '_blank');
  };

  return (
    <Layout>
      <>
        {loading && <Spinners />}
        <Container fluid>
          <Row className="mb-3">
            <Col xs={12} md={5} className="adjusted-pad">
            <Row className="justify-content-between">
                <div className="custom-boxs">
                  <div className="top-section">
                    <Box className="styled-boxs">
                      <Typography variant="h6" className="box-titles">
                      Gider Modülü
                      </Typography>
                    </Box>
                    <Typography variant="body1" className="box-descriptions">
                    Bu kısımda kurumun mevcut gider durumu ve giderlerin
                      çeşitli alt kalemlere göre dağılımları görülmektedir.
                      Yönetici ilgili dağılımları inceleyerek gerekli stratejik
                      planlamayı yapabilir.
                    </Typography>
                    <div className="plus-button-container" onClick={handleShow}>
                      <FaPlus className="plus-button" />
                      <span className="tooltip-text">Veri Girişi</span>
                    </div>
                    <div>
                      <h2>Excel Dosyası Yükle</h2>
                      <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChangeGider}
                      />
                      
                      <button onClick={handleUploadGider}>Yükle</button>
                      <Button type="primary" onClick={handleExportGider}>
                      Giderleri Dışa Aktar
                      </Button>
                      
                      <Button type="primary" color="red" onClick={handleDownload}>
                        Excel Şablonunu İndir
                      </Button>
                      <Button type="primary" onClick={handleRedirect}>
                        İmport işlemi nasıl yapılır
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
            <Col md={1}></Col>
            <Col xs={12} md={6}>
              <Box className="styled-box">
                <Typography variant="h2">Gider</Typography>
                <div className="divider"></div>
                <div className="styled-value">
                  {totalTutarFilteredExpenditures.toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  <CurrencyLiraIcon className="styled-currency" />
                </div>
                <div className="mb-3">
                  <CircularProgressBar progress={progress} />
                </div>
              </Box>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4} className="adjusted-top">
              <Form.Group>
                <Form.Label>Yıllar</Form.Label>
                <Select
                  mode="multiple"
                  placeholder="Yıllar"
                  onChange={(value) => setSelectedYears(value)}
                  style={{ width: "100%" }}
                >
                  {availableYears.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>Aylar</Form.Label>
                <Select
                  mode="multiple"
                  placeholder="Aylar"
                  onChange={(value) => setSelectedMonths(value)}
                  style={{ width: "100%" }}
                >
                  {availableMonths.map((month) => (
                    <Option key={month} value={month}>
                      {turkishMonths[month - 1]}
                    </Option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>Bölge</Form.Label>
                <Select
                  mode="multiple"
                  placeholder="Bölge"
                  onChange={(value) => setSelectedCountries(value)}
                  style={{ width: "100%" }}
                >
                  {availableCountries.map((country) => (
                    <Option key={country} value={country}>
                      {country}
                    </Option>
                  ))}
                </Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={8}>
              <Parapara
                totalFilteredExpenditures={totalFilteredExpenditures}
                calculateTotalIncomeByExchange={exchangeRates}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={5}>
              <div className="custom-box">
                <h3 className="custom-header">Country-wise Expenditures</h3>
                <div>
                  <Table className="custom-table">
                    <tbody>
                      {Object.entries(totalExpenseByCountry).map(
                        ([country, income], index) => {
                          const incomeRatio = (
                            (income / totalFilteredExpenditures) *
                            100
                          ).toFixed(2);
                          return (
                            <tr key={index}>
                              <td
                                style={{ width: "1px", whiteSpace: "nowrap" }}
                              >
                                &#8226; {/* Nokta işareti */}
                              </td>
                              <td>{country}</td>
                              <td>{income.toFixed(2)} ₺</td>
                              <td>{incomeRatio}%</td>{" "}
                              {/* Yüzdelik sütunu üçüncü olarak eklendi */}
                              <td>
                                <div className="table-progress-bar">
                                  <div
                                    className="table-progress"
                                    style={{ width: `${incomeRatio}%` }}
                                  ></div>
                                </div>
                              </td>
                              {/* Tutar sütunu dördüncü olarak eklendi */}
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="graph-container">
                  <GraphComponent data={monthlyExpenditures} />
                </div>
              </div>
            </Col>

            <Col md={7}>
              <div className="custom-box">
                <h3 className="custom-header">Proje-wise Expenditures</h3>
                <div className="custom-table-container">
                  <Table className="custom-table">
                    <tbody>
                      {Object.entries(totalExpenseByProje).map(
                        ([alt_gider_turu, kalemler], index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td colSpan="5" style={{ fontWeight: "bold" }}>
                                {alt_gider_turu}
                              </td>
                            </tr>
                            {Object.entries(kalemler).map(
                              ([alt_gider_kalemi, faincome], subIndex) => {
                                const incomeRatio = (
                                  (faincome / totalFilteredExpenditures) *
                                  100
                                ).toFixed(2);
                                return (
                                  <tr key={subIndex}>
                                    <td
                                      style={{
                                        width: "1px",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      &#8226; {/* Nokta işareti */}
                                    </td>
                                    <td>{alt_gider_kalemi}</td>
                                    <td>{faincome.toFixed(2)} ₺</td>
                                    <td>{incomeRatio}%</td>
                                    <td>
                                      <div className="table-progress-bar">
                                        <div
                                          className="table-progress"
                                          style={{ width: `${incomeRatio}%` }}
                                        ></div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </React.Fragment>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <CommonModal
          ref={modalRef}
          showModal={showModal}
          handleClose={handleClose}
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          getCurrentDate={getCurrentDate}
          statusMessage={statusMessage}
          loading={loading}
          formFields={formFields}
          showConfirmation={showConfirmation}
          handleConfirmationClose={handleConfirmationClose}
          confirmSubmit={confirmSubmit}
          giderTurleri={giderTurleri}
          altGT={altGT}
          altGK={altGK}
          bolgeler={bolgeler}
        />
      </>
    </Layout>
  );
};

export default Gider;
