import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Header = () => {
  const [loginUser, setLoginUser] = useState("");
  const { user, logout, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setLoginUser(user);
    }
  }, []);

  const logoutHandler = () => {
    logout();
    
    navigate("/login");
  };

  const goToHomePage2 = () => {
    navigate("/gelir"); // Rota düzeltildi
  };
  const goToHomePage3 = () => {
    navigate("/gider"); // Rota düzeltildi
  };
  const goToHomePage4 = () => {
    navigate("/genel"); // Rota düzeltildi
  };
  const goToHomePage5 = () => {
    navigate("/gelirDetay"); // Rota düzeltildi
  };
  const goToHomePage6 = () => {
    navigate("/giderDetay"); // Rota düzeltildi
  };

  const goToHomePage7 = () => {
    navigate("/gelir-edit"); // Rota düzeltildi
  };

  const goToHomePage8 = () => {
    navigate("/gider-edit"); // Rota düzeltildi
  };


 


  if (loading) {
    return null; // Yüklenme durumunda herhangi bir şey render etmeyin
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <Link className="navbar-brand" to="/">
              Veri Analizi
            </Link>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <span className="nav-link" onClick={goToHomePage2}>
                  Gelir
                </span>
              </li>

              <li className="nav-item">
                <span className="nav-link" onClick={goToHomePage3}>
                  Gider
                </span>
              </li>

              <li className="nav-item">
                <span className="nav-link" onClick={goToHomePage4}>
                  Genel
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link" onClick={goToHomePage5}>
                  GelirDetay
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link" onClick={goToHomePage6}>
                  GiderDetay
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link" onClick={goToHomePage7}>
                  Gelir Edit
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link" onClick={goToHomePage8}>
                  Gider Edit
                </span>
              </li>
              {user && user.isAdmin && (
                 <li className="nav-item">
                  <Link className="nav-link" to="/admin-panel">
                    Admin
                  </Link>
              </li> )}

              
            </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <p className="nav-link">{user ? `Sayın, ${user.username} Hoşgeldiniz` : "Giriş yapılmadı"}</p>
              </li>
              <li className="nav-item">
                {user && (
                  <button className="btn btn-primary" onClick={logoutHandler}>
                    Çıkış Yap
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
