import React from 'react';

const footerStyle = {
  background: '#f1f1f1',
  textAlign: 'center',
  padding: '10px 0',
  width: '100%',
  marginTop: 'auto', // Sayfanın en altında kalmasını sağlar
};

const Footer = () => {
  return (
    <div style={footerStyle}>
      <p>Tüm Hakları Saklıdır &copy; ATOM-UP </p>
    </div>
  );
};

export default Footer;
