import React from "react";
import "../App.scss";
import Layout from "../Layout/Layout";


const MainContent = () => {
  return (
    <div className="main-content">
      <div className="intro">
        <h2>Yapay Zeka ve Gelecek</h2>
        <p>
          Yapay zeka, dünyayı değiştirme potansiyeline sahip en yenilikçi
          teknolojilerden biridir. İşte yapay zekanın çeşitli alanlardaki
          uygulamaları ve etkileri.
        </p>
      </div>
    
    </div>
  );
};

// App Bileşeni
const App = () => {
  return (
    <Layout>
      <div className="App">
        <MainContent />
      </div>
    </Layout>
  );
};

export default App;
