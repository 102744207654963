import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const PublicRoute = () => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>; // Yüklenme durumu
    }

    if (user) {
        if (user.isSuspended) {
            return <Navigate to="/suspended" />;
        }

        return <Navigate to="/" />;
    }

    return <Outlet />;
};

export default PublicRoute;
