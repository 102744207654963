import React from "react";
import { Form } from "react-bootstrap";
import { Select } from "antd";

const { Option } = Select;

const FilterSection = ({
  availableYears,
  availableMonths,
  availableCountries,
  setSelectedYears,
  setSelectedMonths,
  setSelectedCountries,
  turkishMonths,
}) => {
  return (
    <>
      <Form.Group>
        <Form.Label>Yıllar</Form.Label>
        <Select
          mode="multiple"
          placeholder="Yıllar"
          onChange={setSelectedYears}
          style={{ width: "100%" }}
        >
          {availableYears.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>Aylar</Form.Label>
        <Select
          mode="multiple"
          placeholder="Aylar"
          onChange={setSelectedMonths}
          style={{ width: "100%" }}
        >
          {availableMonths.map((month) => (
            <Option key={month} value={month}>
              {turkishMonths[month - 1]}
            </Option>
          ))}
        </Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>Bölge</Form.Label>
        <Select
          mode="multiple"
          placeholder="Bölge"
          onChange={setSelectedCountries}
          style={{ width: "100%" }}
        >
          {availableCountries.map((country) => (
            <Option key={country} value={country}>
              {country}
            </Option>
          ))}
        </Select>
      </Form.Group>
    </>
  );
};

export default FilterSection;
