import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import Layout from "../../Layout/Layout";

const UserDataPage = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/editor/get-user-data');
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        message.error('Veriler getirilirken hata oluştu');
      }
    };

    fetchData();
  }, []);

  const handleEdit = (record) => {
    setEditingData(record);
    setIsModalVisible(true);
  };

  const handleDelete = (id) => {
    setDeletingId(id);
    setIsDeleteConfirmVisible(true); // Silme onay popup'ını göster
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`/editor/delete-user-data/${deletingId}`);
      setUserData(userData.filter((item) => item._id !== deletingId));
      message.success('Veri başarıyla silindi');
    } catch (error) {
      message.error('Veri silinirken hata oluştu');
    } finally {
      setIsDeleteConfirmVisible(false); // Onay popup'ını kapat
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteConfirmVisible(false);
    setDeletingId(null);
  };

  const handleOk = async (values) => {
    setIsConfirmVisible(true); // Onay popup'ını göster
  };

  const handleConfirmOk = async () => {
    try {
      const response = await axios.put(`/editor/edit-user-data/${editingData._id}`, editingData);
      setUserData(userData.map((item) => (item._id === editingData._id ? response.data.data : item)));
      setIsModalVisible(false);
      setIsConfirmVisible(false);
      message.success('Veri başarıyla güncellendi');
    } catch (error) {
      message.error('Veri güncellenirken hata oluştu');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingData(null);
  };

  const handleConfirmCancel = () => {
    setIsConfirmVisible(false);
  };

  const columns = [
    { title: 'Tarih', dataIndex: 'tarih', key: 'tarih' },
    { title: 'Ana Gelir Türü', dataIndex: 'ana_gelir_turu', key: 'ana_gelir_turu' },
    { title: 'Faaliyet', dataIndex: 'faaliyet', key: 'faaliyet' },
    { title: 'Kaynak', dataIndex: 'kaynak', key: 'kaynak' },
    { title: 'Bölge', dataIndex: 'bolge', key: 'bolge' },
    { title: 'Değer', dataIndex: 'deger', key: 'deger' },
    { title: 'Döviz', dataIndex: 'doviz', key: 'doviz' },
    { title: 'Tutar', dataIndex: 'tutar', key: 'tutar' },
    { title: 'Tür', dataIndex: 'tur', key: 'tur' },
    { title: 'Partner', dataIndex: 'partner', key: 'partner' },
    { title: 'Açıklama', dataIndex: 'aciklama', key: 'aciklama' },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>Düzenle</Button>
          <Button type="link" danger onClick={() => handleDelete(record._id)}>Sil</Button>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <>
        <Table columns={columns} dataSource={userData} loading={loading} rowKey="_id" />
        <Modal
          title="Veriyi Düzenle"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            initialValues={editingData}
            onFinish={handleOk}
            layout="vertical"
          >
            <Form.Item name="tarih" label="Tarih">
              <Input />
            </Form.Item>
            <Form.Item name="ana_gelir_turu" label="Ana Gelir Türü">
              <Input />
            </Form.Item>
            <Form.Item name="faaliyet" label="Faaliyet">
              <Input />
            </Form.Item>
            <Form.Item name="kaynak" label="Kaynak">
              <Input />
            </Form.Item>
            <Form.Item name="bolge" label="Bölge">
              <Input />
            </Form.Item>
            <Form.Item name="deger" label="Değer">
              <Input />
            </Form.Item>
            <Form.Item name="doviz" label="Döviz">
              <Input />
            </Form.Item>
            <Form.Item name="tutar" label="Tutar">
              <Input />
            </Form.Item>
            <Form.Item name="tur" label="Tür">
              <Input />
            </Form.Item>
            <Form.Item name="partner" label="Partner">
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label="Açıklama">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Onay"
          visible={isConfirmVisible}
          onOk={handleConfirmOk}
          onCancel={handleConfirmCancel}
          okText="Evet"
          cancelText="Hayır"
        >
          <p>Bu değişiklikleri kaydetmek istediğinize emin misiniz?</p>
        </Modal>
        <Modal
          title="Silme Onayı"
          visible={isDeleteConfirmVisible}
          onOk={confirmDelete}
          onCancel={handleDeleteCancel}
          okText="Evet"
          cancelText="Hayır"
        >
          <p>Bu veriyi silmek istediğinize emin misiniz?</p>
        </Modal>
      </>
    </Layout>
  );
};

export default UserDataPage;
